import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function ScreenTitle({ title = 'Elva Golf' }) {
    const location = useLocation();

    useEffect(() => {
        document.title = title;
    }, [location, title]);

    return null;
}
