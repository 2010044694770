import { Dialog, DialogDismiss, DialogHeading, Focusable } from '@ariakit/react';
import { useEffect, useState, KeyboardEvent } from 'react';
import { motion } from 'motion/react';
import { SupabaseClient } from '@supabase/supabase-js';

import { type Tag, createNewTag, deleteTag, editTag } from '@core';
import { typography } from '../../styles/typography.css';
import { CircularClose } from '../../components/icons/CircularClose';
import { useUserSettingsStore } from '../../state/userSettingsStore';

import * as css from './TagSelection.css';

export const TagModal = ({
    modalOpen,
    setModalOpen,
    editTag: tagToEdit,
    setEditTag,
    selectedTags,
    setSelectedTags,
    supabase,
}: {
    modalOpen: 'add' | 'edit' | undefined;
    setModalOpen: (open: 'add' | 'edit' | undefined) => void;
    editTag: Tag | undefined;
    setEditTag: (tag: Tag | undefined) => void;
    selectedTags: Tag[];
    setSelectedTags: (tags: Tag[]) => void;
    supabase: SupabaseClient;
}) => {
    const [value, setValue] = useState(tagToEdit?.name || '');

    const { availableTags, actions } = useUserSettingsStore((state) => state);

    useEffect(() => {
        setValue(tagToEdit?.name || '');
    }, [tagToEdit]);

    const onAddTag = async () => {
        if (selectedTags.some((tag) => tag.name === value) || value === '') return;

        const tag = await createNewTag(supabase, value);
        setSelectedTags([...selectedTags, tag]);
        actions.setAvailableTags([...availableTags, tag]);
    };

    const onEditTag = () => {
        if (tagToEdit && selectedTags.find((tag) => tag.id === tagToEdit.id)) {
            setSelectedTags(
                selectedTags.map((tag) => {
                    if (tag.id === tagToEdit.id) {
                        return { ...tag, name: value };
                    }
                    return tag;
                }),
            );
        }
        actions.setAvailableTags(
            availableTags.map((tag) => {
                if (tag.id === tagToEdit?.id) {
                    return { ...tag, name: value };
                }
                return tag;
            }),
        );
        tagToEdit && editTag(supabase, tagToEdit.id, value);
    };

    const onDeleteTag = () => {
        if (!tagToEdit) return;

        if (selectedTags.some((tag) => tag.id === tagToEdit.id)) {
            setSelectedTags(selectedTags.filter((tag) => tag.id !== tagToEdit.id));
        }

        actions.setAvailableTags(availableTags.filter((tag) => tag.id !== tagToEdit.id));
        deleteTag(supabase, tagToEdit?.id);
    };

    const onClose = () => {
        setModalOpen(undefined);
        setValue('');
        setEditTag(undefined);
    };

    const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            modalOpen === 'edit' ? onEditTag() : onAddTag();
            onClose();
        }
    };

    return (
        <Dialog
            open={modalOpen !== undefined}
            onClose={onClose}
            className={css.modal}
            backdrop={
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    style={{
                        backgroundColor: 'rgb(0,0,0,0.3)',
                    }}
                />
            }
            render={
                <motion.div
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.95 }}
                />
            }
        >
            <div className={css.modalTop}>
                <DialogHeading
                    className={typography({
                        size: 'h3',
                        weight: 'bold',
                        color: 'dark',
                    })}
                >
                    {modalOpen === 'add' ? 'Add Tag' : 'Edit Tag'}
                </DialogHeading>
                <DialogDismiss>
                    <CircularClose width={24} />
                </DialogDismiss>
            </div>
            <Focusable
                autoFocus
                render={
                    <input
                        className={css.modalInput}
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        onKeyUp={(e) => handleKeyUp(e)}
                    />
                }
            />
            <div className={css.modalButtons}>
                {modalOpen === 'edit' && (
                    <DialogDismiss onClick={onDeleteTag} className={css.modalButton({ variant: 'delete' })}>
                        Delete
                    </DialogDismiss>
                )}
                <DialogDismiss
                    onClick={modalOpen === 'edit' ? onEditTag : onAddTag}
                    className={css.modalButton({ variant: 'save' })}
                >
                    {modalOpen === 'edit' ? 'Save' : 'Add'}
                </DialogDismiss>
            </div>
        </Dialog>
    );
};
