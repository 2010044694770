import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import type { SupabaseClient } from '@supabase/supabase-js';
import { ActivityContextProviderProps, ActivityContextType } from './ActivityContext.types';
import { useSession } from '../../hooks/useSession';
import { setActiveSwingByID, useGlobalStore } from '../../../state/globalStore';
import { useUserSettingsStore } from '../../../state/userSettingsStore';
import { useBoothSession } from '../../hooks/useBoothSession';
import {
    Club,
    Swing,
    createNewActivity,
    Tag,
    updateActivityClub,
    updateActivityTags,
    ANALYSIS_TYPES,
    Analysis,
} from '@common';
import DataManager from '../../../DataManager';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';

export const ActivityContext = createContext<ActivityContextType | undefined>(undefined);

export function useActivityContext() {
    const context = useContext(ActivityContext);
    if (!context) {
        throw new Error('useActivityContext must be used within an ActivityContextProvider');
    }
    return context;
}

export const ActivityContextProvider = ({
    children,
    supabaseClient,
}: ActivityContextProviderProps & { supabaseClient: SupabaseClient }) => {
    const queryClient = useQueryClient();

    const { user } = useSession();
    const { boothSessionDetails } = useBoothSession();
    // The current activity where all swings are stored
    const [currentActivityId, setCurrentActivityId] = useState<number>(-1);
    // The activity that is being viewed
    const [activityId, setActivityId] = useState<number>(-1);
    const { activityTags, activityClub } = useUserSettingsStore((state) => state);
    const isCurrentActivity = currentActivityId === activityId || currentActivityId === -1;

    const { data: activity } = useQuery({
        queryKey: ['activities', { id: activityId }],
        queryFn: () => DataManager.getInstance().getActivity(activityId ?? -1),
        enabled: activityId > 0,
    });

    if (activity) {
        activity.club_id = activityClub?.id ?? null;
        activity.club = activityClub ?? null;
        activity.tags = activityTags ?? [];
    }

    const { data: swings } = useQuery({
        queryKey: ['swings', { id: activityId }],
        queryFn: () => DataManager.getInstance().getSwings(activityId ?? -1),
        enabled: activityId > 0,
    });

    // Used to change the club
    const selectClub = async (club: Club) => {
        if (!user?.id || !boothSessionDetails?.id) {
            return;
        }

        // If current activity has no swings, re-use the activity, don't create a new one and leave the other one empty
        if (swings && swings.length === 0) {
            if (activityId) {
                updateActivityClub(supabaseClient, club, activityId);
            }

            // Send activityClub -> store -> websocket.
            useUserSettingsStore.setState({
                activityClub: club,
            });
        } else {
            const activityId = await createNewActivity(
                supabaseClient,
                user?.id,
                boothSessionDetails?.id,
                club,
                activityTags,
            );

            if (activityId) {
                setActivityId(activityId);
                setCurrentActivityId(activityId);

                // Send activityId -> store -> websocket.
                useUserSettingsStore.setState({
                    activityId,
                    activityClub: club,
                });
            }
        }
    };

    const changeTags = async (tags: Tag[]) => {
        if (user && boothSessionDetails?.id) {
            // If current activity has no swings, re-use the activity, don't create a new one and leave the other one empty
            if (swings && swings.length === 0) {
                if (activityId) {
                    updateActivityTags(supabaseClient, tags, activityId);
                }

                // Send activityTags -> store -> websocket.
                useUserSettingsStore.setState({
                    activityTags: tags,
                });
            } else {
                const activityId = await createNewActivity(
                    supabaseClient,
                    user.id,
                    boothSessionDetails?.id,
                    activityClub,
                    tags,
                );

                if (activityId) {
                    setActivityId(activityId);
                    setCurrentActivityId(activityId);

                    // Send activityId -> store -> websocket.
                    useUserSettingsStore.setState({
                        activityId,
                        activityTags: tags,
                    });
                }
            }
        }
    };

    const selectSwing = (swing: Swing) => {
        // Check if it's ready to be viewed
        if ((swing.quickAnalysis || swing.fullAnalysis) && swing?.id) {
            setActiveSwingByID(swing.id);
        }
    };

    const showActivity = (activityId: number) => {
        setActivityId(activityId);
    };

    const showCurrentActivity = async () => {
        let activityId = currentActivityId;
        if (activityId === -1) {
            activityId = await DataManager.getInstance().getCurrentActivityId();
        }

        setActivityId(activityId ?? -1);

        //TODO(at): Move to realtime code
        // Send activityId -> store -> websocket.
        useUserSettingsStore.setState({
            activityId,
        });
    };

    const showFloorActivity = (activityId: number) => {
        setActivityId(activityId);
        setCurrentActivityId(activityId);
    };

    useEffect(() => {
        if (!user) {
            return;
        }

        const getCurrentActivityId = async () => {
            if (currentActivityId !== -1) {
                return;
            }

            const activityId = await DataManager.getInstance().getCurrentActivityId();
            setCurrentActivityId(activityId);
        };

        const fetchClubs = async () => {
            const clubs = await DataManager.getInstance().getClubs();

            useGlobalStore.setState({ clubs });
        };

        getCurrentActivityId();
        fetchClubs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, currentActivityId]);

    //TODO(at): Move state to this context
    useEffect(() => {
        const getAndSyncClubsAndTags = async () => {
            if (!activityId || activityId === -1) return;

            const { activityClub, activityTags } = await DataManager.getInstance().getClubsAndTags(activityId);

            useUserSettingsStore.setState((prev) => ({
                activityClub: activityClub ?? prev.activityClub,
                activityTags: activityTags ?? prev.activityTags,
            }));
        };

        //TODO(at): Refactor to detect connectivity problems and recover
        // This prevents devices from being initially out of sync, or going out of sync due to a temporary network problem.
        // We send the activityId to the store and through websocket in a heartbeat to make sure all listeners have the correct activityId.
        const intervalId = setInterval(() => {
            useUserSettingsStore.setState({
                activityId: currentActivityId,
            });
        }, 5000);

        getAndSyncClubsAndTags();

        return () => {
            clearInterval(intervalId);
        };
    }, [activityId, currentActivityId]);

    //TODO(at): Add a loading state
    // if (activityPending || swingsPending) {
    //     return <Spinner size="l" isAbsolute />;
    // }

    const addAnalysisToSwing = useCallback(
        (swingId: number, type: ANALYSIS_TYPES, analysis: Analysis) => {
            const isQuickAnalysis = type === ANALYSIS_TYPES.QUICK_ANALYSIS;

            queryClient.setQueryData<Swing[]>(['swings', { id: currentActivityId }], (swings) => {
                const exists = _.find(swings, (swing) => swing.id === swingId);

                if (exists) {
                    // Update the existing analysis
                    return _.map(swings, (swing) =>
                        swing.id === swingId
                            ? ({
                                  ...swing,
                                  id: swingId,
                                  activityID: currentActivityId,
                                  createdAt: exists.createdAt || swing.createdAt || Date.now(),
                                  lastUpdated: Date.now(),
                                  quickAnalysis: isQuickAnalysis ? analysis : swing.quickAnalysis,
                                  fullAnalysis: isQuickAnalysis ? swing.fullAnalysis : analysis,
                                  isFavorite: false,
                                  name: null,
                              } as Swing)
                            : swing,
                    );
                } else {
                    // Add a new analysis
                    return [
                        {
                            id: swingId,
                            activityID: currentActivityId,
                            createdAt: Date.now(),
                            lastUpdated: Date.now(),
                            quickAnalysis: isQuickAnalysis ? analysis : null,
                            fullAnalysis: isQuickAnalysis ? null : analysis,
                            isFavorite: false,
                            name: null,
                        } as Swing,
                        ...(swings || []),
                    ];
                }
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentActivityId],
    );

    const updateSwing = useCallback(
        (updatedSwing: Partial<Swing>) => {
            queryClient.setQueryData<Swing[]>(['swings', { id: activityId }], (swings) => {
                return _.map(swings, (swing) =>
                    swing.id === updatedSwing.id
                        ? ({
                              ...swing,
                              ...updatedSwing,
                          } as Swing)
                        : swing,
                );
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [activityId],
    );

    return (
        <ActivityContext.Provider
            value={{
                activityId,
                currentActivityId,
                activity,
                isCurrentActivity,
                swings: swings ?? [],
                showActivity,
                showCurrentActivity,
                showFloorActivity,
                selectClub,
                changeTags,
                selectSwing,
                updateSwing,
                addAnalysisToSwing,
            }}
        >
            {children}
        </ActivityContext.Provider>
    );
};
