import { useCallback, useEffect, useRef, useState } from 'react';
import type { RefObject } from 'react';

type Size = {
    width: number | undefined;
    height: number | undefined;
    scrollWidth: number | undefined;
    scrollHeight: number | undefined;
};

type UseResizeObserverOptions<T extends HTMLElement = HTMLElement> = {
    ref: RefObject<T>;
    onResize?: (size: Size) => void;
};

const initialSize: Size = {
    width: undefined,
    height: undefined,
    scrollWidth: undefined,
    scrollHeight: undefined,
};

export function useResizeObserver<T extends HTMLElement = HTMLElement>(options: UseResizeObserverOptions<T>): Size {
    const { ref } = options;
    const [{ width, height, scrollWidth, scrollHeight }, setSize] = useState<Size>(initialSize);
    const isMounted = useIsMounted();
    const previousSize = useRef<Size>({ ...initialSize });
    const onResize = useRef<((size: Size) => void) | undefined>(undefined);
    onResize.current = options.onResize;

    useEffect(() => {
        if (!ref.current) return;

        if (typeof window === 'undefined' || !('ResizeObserver' in window)) return;

        const observer = new ResizeObserver(([entry]) => {
            const newWidth = ~~(extractSize(entry, 'inlineSize') ?? 0);
            const newHeight = ~~(extractSize(entry, 'blockSize') ?? 0);

            const hasChanged = previousSize.current.width !== newWidth || previousSize.current.height !== newHeight;

            if (hasChanged) {
                // Grab new scroll dimensions
                const scrollHeight = ~~entry.target.scrollHeight;
                const scrollWidth = ~~entry.target.scrollWidth;

                const newSize: Size = { width: newWidth, height: newHeight, scrollWidth, scrollHeight };
                previousSize.current.width = newWidth;
                previousSize.current.height = newHeight;

                if (onResize.current) {
                    onResize.current(newSize);
                } else {
                    if (isMounted()) {
                        setSize(newSize);
                    }
                }
            }
        });

        observer.observe(ref.current, { box: 'border-box' });

        return () => {
            observer.disconnect();
        };
    }, [ref, isMounted]);

    return { width, height, scrollWidth, scrollHeight };
}

function extractSize(entry: ResizeObserverEntry, sizeType: keyof ResizeObserverSize): number | undefined {
    const key = 'borderBoxSize';

    if (!entry[key]) {
        return entry.contentRect[sizeType === 'inlineSize' ? 'width' : 'height'];
    }

    return Array.isArray(entry[key])
        ? entry[key][0][sizeType]
        : // @ts-expect-error Support Firefox's non-standard behavior
          (entry[key][sizeType] as number);
}

function useIsMounted(): () => boolean {
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    return useCallback(() => isMounted.current, []);
}
