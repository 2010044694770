import { motion, useTransform, useMotionValueEvent, useDragControls } from 'motion/react';
import { useCallback, useLayoutEffect, useMemo, useRef, type PointerEvent } from 'react';
import _ from 'lodash';

import type { Analysis, Nil } from '@core';
import { constants } from '@core';
import { useResizeObserver } from '../../../utils/hooks/useResizeObserver';
import { useVideoPlaybackStore } from '../../VideoPlayback/VideoPlayback.state';
import { ScrubberHandle } from '../../icons/ScrubberHandle';
import { calculateFrameRatios } from '../../../utils/calculateFrameRatios';
import { generatePositionTicks, getSwingFramesInfo } from './helpers';

import * as css from './PlaybackScrubber.css';

type PlaybackScrubberProps = {
    speed: number;
    activeSwingAnalysis: Analysis;
    comparisonSwingAnalysis: Analysis | Nil;
    segmentationOffset: SegmentationOffset;
    showComparison: boolean;
};

type SegmentationOffset = {
    current: number;
    comparison: number;
    difference: number;
};

export default function PlaybackScrubber({
    speed,
    activeSwingAnalysis,
    comparisonSwingAnalysis,
    segmentationOffset,
    showComparison,
}: PlaybackScrubberProps) {
    const ref = useRef<HTMLDivElement>(null);
    const rafId = useRef<number>();
    const rafLastTime = useRef(performance.now());
    const rafAccumulator = useRef(0);
    const programmaticallyDragging = useRef(false);
    const dragControls = useDragControls();
    const handleX = useVideoPlaybackStore((state) => state.handlerMotionValue);
    const travelDistance = useVideoPlaybackStore((state) => state.travelDistance);
    const activeSwingInfo = getSwingFramesInfo(activeSwingAnalysis);
    const comparisonSwingInfo = getSwingFramesInfo(comparisonSwingAnalysis);

    const totalFrames = showComparison
        ? Math.max(activeSwingInfo.totalFrames ?? 0, comparisonSwingInfo.totalFrames ?? 0)
        : activeSwingInfo.totalFrames ?? 0;

    const playbackTrackProgress = useTransform(handleX, [0, travelDistance], [0, 1]);

    const [activeSwingFrameRatios, comparisonSwingFrameRatios] = useMemo(() => {
        const activeSegmentation = _.values(activeSwingInfo?.segmentation ?? {});
        const comparisonSegmentation = _.values(comparisonSwingInfo?.segmentation ?? {});

        return [
            totalFrames ? calculateFrameRatios(activeSegmentation, totalFrames) : {},
            comparisonSwingInfo && totalFrames && segmentationOffset
                ? calculateFrameRatios(comparisonSegmentation, totalFrames, segmentationOffset.difference)
                : {},
        ];
    }, [activeSwingInfo, comparisonSwingInfo, totalFrames, segmentationOffset]);

    /**
     * Colored ticks on the scrubber
     */
    const ticks = generatePositionTicks(activeSwingFrameRatios, showComparison ? comparisonSwingFrameRatios : null);

    useResizeObserver({
        ref,
        onResize(size) {
            useVideoPlaybackStore.setState({ travelDistance: size.width });
        },
    });

    // Monitor when user scrubs the scrubber
    useMotionValueEvent(handleX, 'change', (latest) => {
        if (programmaticallyDragging.current) {
            return;
        }

        const progress = latest / travelDistance;
        const currentFrame = Math.floor(progress * totalFrames);

        useVideoPlaybackStore.setState({ replayProgress: ~~currentFrame / totalFrames });
    });

    // This layout effect handles running the rAF loop when video is auto-playing.
    useLayoutEffect(() => {
        const TARGET_FPS = 60;
        const REALTIME_FACTOR = constants.CAMERA_FPS / constants.VIDEO_FPS;

        // Video duration at the video's native FPS
        const VIDEO_DURATION_SEC = totalFrames / constants.VIDEO_FPS;
        const TARGET_FRAMETIME = 1000 / TARGET_FPS;

        // Multiply speed by REALTIME_FACTOR so that speed=1 matches real life
        const INCREMENT_PER_FRAME = (speed * REALTIME_FACTOR) / (TARGET_FPS * VIDEO_DURATION_SEC);

        const animate = (currentTime: number) => {
            const deltaTime = currentTime - rafLastTime.current;
            rafAccumulator.current += deltaTime;

            while (rafAccumulator.current >= TARGET_FRAMETIME) {
                useVideoPlaybackStore.setState((prev) => {
                    const nextProgress = prev.replayProgress + INCREMENT_PER_FRAME;
                    const loopedProgress = nextProgress % 1;

                    // Move the handle
                    handleX.set(loopedProgress * travelDistance);

                    return { replayProgress: loopedProgress };
                });
                rafAccumulator.current -= TARGET_FRAMETIME;
            }

            rafLastTime.current = currentTime;
            rafId.current = requestAnimationFrame(animate);
        };

        const unsubscribe = useVideoPlaybackStore.subscribe(
            (state) => state.autoPlaying,
            (autoPlaying) => {
                if (autoPlaying) {
                    programmaticallyDragging.current = true;
                    rafLastTime.current = performance.now();
                    rafAccumulator.current = 0;
                    rafId.current = requestAnimationFrame(animate);
                } else {
                    if (rafId.current) {
                        cancelAnimationFrame(rafId.current);
                    }
                    programmaticallyDragging.current = false;
                }
            },
        );

        return () => {
            if (rafId.current) {
                cancelAnimationFrame(rafId.current);
            }
            unsubscribe();
        };
    }, [travelDistance, handleX, speed, totalFrames]);

    const handlePointerDown = useCallback(
        (e: PointerEvent<HTMLElement>) => {
            // Stop playing
            useVideoPlaybackStore.setState({ autoPlaying: false });
            // Snap to cursor
            requestAnimationFrame(() => dragControls.start(e, { snapToCursor: true }));
        },
        [dragControls],
    );

    return (
        <div className={css.root}>
            <motion.div className={css.positioner} ref={ref} onPointerDown={handlePointerDown}>
                <motion.div
                    className={css.handle}
                    drag="x"
                    dragConstraints={{ left: 0, right: travelDistance }}
                    dragElastic={false}
                    dragMomentum={false}
                    dragDirectionLock
                    dragControls={dragControls}
                    onDragStart={() => useVideoPlaybackStore.setState({ autoPlaying: false })}
                    style={{ x: handleX }}
                >
                    <ScrubberHandle />
                </motion.div>
                <div className={css.scrubber}>
                    <div className={css.scrubberContent}>{ticks}</div>
                </div>
                <div className={css.progressMask}>
                    <motion.div className={css.progress} style={{ scaleX: playbackTrackProgress }} />
                </div>
            </motion.div>
        </div>
    );
}
