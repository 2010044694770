import _ from 'lodash';
import { Corridor } from '@common';
import { SupabaseService } from './services/supabase.service';

export class CorridorsEndpointPlaceholder {
    constructor(private readonly _supabase: SupabaseService) {}

    /**
     * Endpoint for getting all publicly available corridors
     */
    async getAllCorridors(): Promise<Corridor[]> {
        return [
            {
                id: 1,
                name: {
                    'en-US': 'Default Corridor',
                    value: 'Default Corridor',
                },
                parameter_margins: _.mapValues(
                    {
                        distance_between_feet_p1: [30, 60],
                        feet_alignment: [0, 8],
                        feet_ball_position_ratio_p1: [50, 65],
                        feet_distance_from_ball_p1: [50, 85],
                        hand_position_p1: [2, 12],
                        head_bend_p1: [35, 55],
                        head_bend_p2: [30, 50],
                        head_bend_p4: [20, 40],
                        head_bend_p7: [40, 60],
                        head_lift_p2: [-2, 1],
                        head_lift_p4: [-2.5, 1],
                        head_lift_p7: [-8, 1],
                        head_rotation_p1: [-8, 8],
                        head_rotation_p2: [-15, -5],
                        head_rotation_p4: [-20, -40],
                        head_rotation_p7: [10, 40],
                        head_sway_p2: [-3, 0],
                        head_sway_p4: [-7, -2],
                        head_sway_p7: [-2, 3],
                        head_thrust_p2: [-2, 2],
                        head_thrust_p4: [-2, 2],
                        head_thrust_p7: [-2, 2],
                        head_tilt_p1: [-3, 10],
                        head_tilt_p2: [-25, -10],
                        head_tilt_p4: [-30, -10],
                        head_tilt_p7: [0, 20],
                        knees_alignment: [0, 8],
                        pelvis_bend_p1: [26, 40],
                        pelvis_bend_p2: [20, 34],
                        pelvis_bend_p4: [18, 34],
                        pelvis_bend_p7: [15, 30],
                        pelvis_lift_p2: [-2, 0],
                        pelvis_lift_p4: [-3, 0],
                        pelvis_lift_p7: [-5, 5],
                        pelvis_rotation_p1: [0, 8],
                        pelvis_rotation_p2: [-30, -10],
                        pelvis_rotation_p4: [-48, -30],
                        pelvis_rotation_p7: [30, 55],
                        pelvis_sway_p2: [-5, 0],
                        pelvis_sway_p4: [-1.5, 1.5],
                        pelvis_sway_p7: [11, 14],
                        pelvis_thrust_p2: [-2, 1],
                        pelvis_thrust_p4: [-5, 5],
                        pelvis_thrust_p7: [-2, 2],
                        pelvis_tilt_p1: [0, 3],
                        pelvis_tilt_p2: [-8, 0],
                        pelvis_tilt_p4: [-10, -3],
                        pelvis_tilt_p7: [5, 15],
                        right_elbow_angle_p1: [170, 180],
                        right_knee_angle_p1: [120, 160],
                        right_wrist_lift_p2: [8, 25],
                        right_wrist_lift_p4: [65, 90],
                        right_wrist_lift_p7: [-10, 10],
                        right_wrist_sway_p2: [-50, -20],
                        right_wrist_sway_p4: [-50, -10],
                        right_wrist_sway_p7: [3, 23],
                        right_wrist_thrust_p2: [-10, 0],
                        right_wrist_thrust_p4: [-35, -15],
                        right_wrist_thrust_p7: [0, 13],
                        upper_torso_bend_p1: [26, 40],
                        upper_torso_bend_p2: [15, 29],
                        upper_torso_bend_p4: [-5, 5],
                        upper_torso_bend_p7: [24, 39],
                        upper_torso_lift_p2: [-3, 3],
                        upper_torso_lift_p4: [-10, 0],
                        upper_torso_lift_p7: [-3, 3],
                        upper_torso_rotation_p1: [2, 16],
                        upper_torso_rotation_p2: [-55, -25],
                        upper_torso_rotation_p4: [-105, -85],
                        upper_torso_rotation_p7: [25, 55],
                        upper_torso_sway_p2: [-5, 0],
                        upper_torso_sway_p4: [-10, 3],
                        upper_torso_sway_p7: [2, 9],
                        upper_torso_thrust_p2: [-2, 3],
                        upper_torso_thrust_p4: [0, 10],
                        upper_torso_thrust_p7: [-5, 5],
                        upper_torso_tilt_p1: [7, 18],
                        upper_torso_tilt_p2: [-32, -20],
                        upper_torso_tilt_p4: [-40, -28],
                        upper_torso_tilt_p7: [15, 35],
                    },
                    ([min, max]) => ({
                        outer_min: min,
                        inner_min: min,
                        inner_max: max,
                        outer_max: max,
                    }),
                ),
            },
        ];
    }
}
