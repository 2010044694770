import { QRCodeCanvas } from 'qrcode.react';

import VStack from '../../../components/VStack';

import * as css from './QRCodeWithBottomLabel.css';

function QRCodeWithBottomLabel({ qrValue, label }: { qrValue: string; label?: string | null }) {
    return (
        <VStack center spacing={20}>
            <div className={css.qrWrapper}>
                <QRCodeCanvas size={195} value={qrValue} />
            </div>
            {/* <span className={css.label}>{label}</span> */}
        </VStack>
    );
}

export default QRCodeWithBottomLabel;
