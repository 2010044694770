import _ from 'lodash';

import {
    isToggledOn,
    lhsOfFirst,
    UIModule,
    UINodeRelation,
    type AnyUINode,
    type Device,
    type Layout,
    type Nil,
    type UINodeToggler,
    type UINodeTree,
    UISwingFoundations,
    ALL_DEVICES,
    setToggle,
} from '@common';

export function createTogglersForDevice(
    device: Device,
    ...params: Parameters<typeof createTogglersForKiosk>
): UINodeToggler[] {
    switch (device) {
        case 'kiosk':
            return createTogglersForKiosk(...params);
        case 'floor':
            return createTogglersForFloor(...params);
        default:
            return [];
    }
}

function createTogglersForKiosk(
    layout: Layout,
    uiNodeTree: UINodeTree,
    onChange: () => void,
    parentNode?: AnyUINode | Nil,
): UINodeToggler[] {
    const nodeForDevice = getNodesForDevice(uiNodeTree, parentNode, layout, 'kiosk');

    const swingFoundations = _.compact([_.find(nodeForDevice, (n) => n.type === 'swing_foundations')]);
    const graphs = _(nodeForDevice)
        .filter((n) => n.type === 'graph')
        .sortBy((n) => n.categories.graph_type)
        .value();
    const modules = getModules(layout, nodeForDevice);

    return _.map(
        [...swingFoundations, ...graphs, ...modules],
        (n) =>
            ({
                layout,
                childNode: n,
                currentDevices: ['kiosk'],
                defaultDevices: n.type === 'swing_foundations' ? ['kiosk'] : [],
                onChange:
                    n.type === 'swing_foundations'
                        ? () => {
                              syncSwingFoundationParameters(layout, uiNodeTree, n as UISwingFoundations);
                              onChange();
                          }
                        : onChange,
            }) as UINodeToggler,
    );
}

function createTogglersForFloor(
    layout: Layout,
    uiNodeTree: UINodeTree,
    onChange: () => void,
    parentNode?: AnyUINode | Nil,
): UINodeToggler[] {
    const nodesForDevice = getNodesForDevice(uiNodeTree, parentNode, layout, 'floor');

    if (!parentNode) {
        // currently we only support pages as the root ui_node for the floor device
        const pages = _.take(
            _.filter(nodesForDevice, (n) => n.type === 'page'),
            1,
        ); // we only want the first page, for now
        return _.map(
            pages,
            (n, index) =>
                ({
                    layout,
                    childNode: n,
                    currentDevices: ['floor'],
                    defaultDevices: index === 0 ? ['floor'] : [],
                    onChange,
                }) as UINodeToggler,
        );
    }

    if (parentNode.type !== 'page') {
        console.warn('createTogglersForFloor: unsupported parent node type', parentNode);
        return [];
    }

    const swingFoundations = _.filter(nodesForDevice, (n) => n.type === 'swing_foundations');
    const graphs = _.filter(nodesForDevice, (n) => n.type === 'graph');
    const modules = getModules(layout, nodesForDevice);

    return _.map(
        [...swingFoundations, ...graphs, ...modules],
        (n) =>
            ({
                layout,
                parentNode,
                childNode: n,
                currentDevices: ['floor'],
                defaultDevices: [],
                onChange:
                    n.type === 'swing_foundations'
                        ? () => {
                              syncSwingFoundationParameters(layout, uiNodeTree, n as UISwingFoundations);
                              onChange();
                          }
                        : onChange,
            }) as UINodeToggler,
    );
}

function getNodesForDevice(
    uiNodeTree: UINodeTree,
    parentNode: AnyUINode | Nil,
    layout: Layout,
    device: Device,
): AnyUINode[] {
    return _(uiNodeTree.nodes)
        .filter((n) => _.includes(n.show_on, device))
        .concat(
            parentNode
                ? _(uiNodeTree.relations)
                      .concat(layout.customizations as UINodeRelation[])
                      .filter((r) => r.parent_ui_node_id === parentNode?.id && _.includes(r.show_child_on, device))
                      .map((r) => _.find(uiNodeTree.nodes, (n) => n.id === r.child_ui_node_id))
                      .compact()
                      .value()
                : [],
        )
        .uniqBy((n) => n.id)
        .orderBy((n) => n.name?.value ?? n.id)
        .value();
}

export function getModules(layout: Layout, nodeForDevice: AnyUINode[]): UIModule[] {
    // we might want to load up modules from all of the user's layouts, not just the current one.
    // for now, the current layout's modules will have to suffice
    return _(layout.customizations)
        .filter((c) => lhsOfFirst(c.child_ui_node_id, '.') === 'module')
        .uniqBy((c) => c.child_ui_node_id)
        .map((c) => _.find(nodeForDevice, (n) => n.id === c.child_ui_node_id) as UIModule | undefined)
        .compact()
        .value();
}

function syncSwingFoundationParameters(layout: Layout, uiNodeTree: UINodeTree, destination: UISwingFoundations) {
    const destinationParameterTogglers = _(uiNodeTree.relations)
        .concat(layout.customizations as UINodeRelation[])
        .filter((r) => r.parent_ui_node_id === destination.id && _.startsWith(r.child_ui_node_id, 'parameter.'))
        .map(
            (r) =>
                ({
                    layout,
                    parentNode: destination,
                    childNode: _.find(uiNodeTree.nodes, (n) => n.id === r.child_ui_node_id),
                    currentDevices: r.show_child_on || ALL_DEVICES,
                    defaultDevices: r.show_child_on || ALL_DEVICES,
                    onChange: _.noop,
                }) as UINodeToggler,
        )
        .filter((t) => !!t.childNode)
        .value();

    const otherSwingFoundationParameterTogglers = _(uiNodeTree.relations)
        .concat(layout.customizations as UINodeRelation[])
        .filter(
            (n) =>
                n.parent_ui_node_id !== destination.id &&
                _.startsWith(n.parent_ui_node_id, 'swing_foundations.') &&
                _.startsWith(n.child_ui_node_id, 'parameter.'),
        )
        .map(
            (r) =>
                ({
                    layout,
                    parentNode: _.find(uiNodeTree.nodes, (n) => n.id === r.parent_ui_node_id),
                    childNode: _.find(uiNodeTree.nodes, (n) => n.id === r.child_ui_node_id),
                    currentDevices: r.show_child_on || ALL_DEVICES,
                    defaultDevices: r.show_child_on || ALL_DEVICES,
                    onChange: _.noop,
                }) as UINodeToggler,
        )
        .filter((t) => !!t.childNode && !!t.parentNode)
        .value();

    for (const src of otherSwingFoundationParameterTogglers) {
        const dst = _.find(destinationParameterTogglers, (t) => t.childNode?.id === src.childNode?.id);
        if (dst) {
            const isOn = isToggledOn(src);
            setToggle(dst, isOn);
        }
    }
}

export function getMaxTogglers(device: Device) {
    switch (device) {
        case 'kiosk':
            return 32;
        case 'floor':
            return 4;
        default:
            return 0;
    }
}
