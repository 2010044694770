import _ from 'lodash';
import type {
    AnyCategoryID,
    AnyUINode,
    Axis,
    Device,
    LayoutCustomization,
    MetadataOf,
    UINode,
    UINodeID,
    UINodeRelation,
    UINodeTree,
    UINodeType,
    Unit,
} from './ui-node.types';
import { assertTypeIf, type Nil } from './type-utils';
import { inferSwingPosition } from './analysis.utils';

export const ALL_DEVICES: Device[] = ['kiosk', 'floor'];

export const DEFAULT_LAYOUT_NAME = 'Default Layout';

export function isUINodeType<T extends UINode>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    node: any,
    type: UINode['type'],
): node is T {
    return node && node.type === type;
}

export function interpolateChildMetadata(
    customization: LayoutCustomization,
    relations?: UINodeRelation[],
    nodes?: UINode[],
): MetadataOf<AnyUINode> {
    return interpolateMetadata([
        _.find(nodes, (n) => n.id === customization.child_ui_node_id),
        _.find(
            relations,
            (r) =>
                r.child_ui_node_id === customization.child_ui_node_id &&
                (r.parent_ui_node_id || null) === (customization.parent_ui_node_id || null),
        ),
        customization,
    ]);
}
export function interpolateMetadata(
    metadataSources: (MetadataOf<AnyUINode> | LayoutCustomization | UINodeRelation | UINode | Nil)[],
): MetadataOf<AnyUINode> {
    // default is to hide position when Parameter is inside Swing Foundations with a position (i.e. on the floor screen)
    const hide_position_in_name = _.some(
        metadataSources,
        (m) =>
            assertTypeIf<LayoutCustomization>(m, _.has(m, 'child_ui_node_id')) &&
            m.child_ui_node_id.startsWith('parameter.') &&
            m.parent_ui_node_id?.startsWith('swing_foundations.') &&
            inferSwingPosition(m.parent_ui_node_id) === inferSwingPosition(m.child_ui_node_id),
    );

    return _.merge(
        hide_position_in_name ? { hide_position_in_name } : {},
        ..._(metadataSources as (MetadataOf<AnyUINode> & LayoutCustomization & UINodeRelation & UINode)[])
            .map((src) => src?.metadata || src?.child_metadata || src)
            .compact()
            .value(),
    );
}

export function interpolateShowChildOn(
    customization: LayoutCustomization,
    relations?: UINodeRelation[],
    nodes?: UINode[],
): Device[] {
    return (
        customization.show_child_on ||
        relations?.find(
            (r) =>
                r.child_ui_node_id === customization.child_ui_node_id &&
                (r.parent_ui_node_id || null) === (customization.parent_ui_node_id || null),
        )?.show_child_on ||
        nodes?.find((n) => n.id === customization.child_ui_node_id)?.show_on ||
        []
    );
}

export function interpolateChildDisplayOrder(customization: LayoutCustomization, relations?: UINodeRelation[]): number {
    return (
        customization.child_display_order ||
        relations?.find(
            (r) =>
                r.child_ui_node_id === customization.child_ui_node_id &&
                (r.parent_ui_node_id || null) === (customization.parent_ui_node_id || null),
        )?.child_display_order ||
        0
    );
}

export function hasChildOfType<T extends UINodeType>(
    relation: { child_ui_node_id?: string | Nil } | Nil,
    type: T,
): relation is { child_ui_node_id: `${T}.${string}` } {
    return relation?.child_ui_node_id?.startsWith(`${type}.`) || false;
}
export function hasParentOfType<T extends UINodeType>(
    relation: { parent_ui_node_id?: string | Nil } | Nil,
    type: T,
): relation is { parent_ui_node_id: `${T}.${string}` } {
    return relation?.parent_ui_node_id?.startsWith(`${type}.`) || false;
}

export function getAxisL10nID(axis: Axis | Nil, unit: Unit | Nil) {
    if (!axis || !unit) return null;
    const angleOrPosition = unit.startsWith('rad') ? 'angle' : 'position';
    return `axis.${angleOrPosition}.${axis}` as const;
}

export type UINodeCategorizations = { [uiNodeID: UINodeID]: AnyCategoryID[] };

/** Creates a cache for faster category filtering.
 * In the future, we might need to add a currentLayout parameter to this method if user should be able to search within custom modules.
 */
export function getCategorizations(
    uiNodeTree: UINodeTree,
    predicate?: (node: UINode) => boolean,
): UINodeCategorizations {
    return _.transform(
        uiNodeTree.nodes,
        (acc, node) => {
            if (!predicate || predicate(node)) acc[node.id] = getCategorizationsRecursive(uiNodeTree, node);
        },
        {} as UINodeCategorizations,
    );
}

/** Recursively gather the categorizations of a node and its children into a single array */
function getCategorizationsRecursive(uiNodeTree: UINodeTree, node: UINode) {
    const categorizations = _.flatMap(node.categories || {}) as AnyCategoryID[];
    for (const relation of uiNodeTree.relations)
        if (relation.parent_ui_node_id === node.id) {
            const child = uiNodeTree.nodes.find((n) => n.id === relation.child_ui_node_id);
            if (child) categorizations.push(...getCategorizationsRecursive(uiNodeTree, child));
        }
    return categorizations;
}

/**
 * Uses the given {@link categorizations} (generated by {@link getCategorizations})
 * and {@link selectedCategoryIDs} to produce a Set of {@link UINodeID}s that match.
 * @param categorizations The categorizations of the nodes to filter by. This can be generated by calling {@link getCategorizations}.
 * @param selectedCategoryIDs The categories to filter by.
 */
export function filterByCategory(
    categorizations: UINodeCategorizations,
    selectedCategoryIDs: Set<AnyCategoryID>,
): Set<UINodeID> {
    // optimized
    const filteredIDs = new Set<UINodeID>();
    const selectedCategoryIDArray = Array.from(selectedCategoryIDs);
    for (const id in categorizations) {
        const categories = categorizations[id as UINodeID];
        if (_.every(selectedCategoryIDArray, (x) => categories.includes(x))) filteredIDs.add(id as UINodeID);
    }
    return filteredIDs;
}
