// TODO: Modals should use ariakit Dialog.
import { useState } from 'react';
import ReactDOM from 'react-dom';
import { motion } from 'motion/react';

import { Button } from '@ui';
import HStack from '../../HStack';
import Input from '../../Input';
import VStack from '../../VStack';

import * as styles from './SwingNameModal.css';

const SwingNameModal = ({
    isOpen,
    setIsOpen,
    onSubmit,
}: {
    isOpen: boolean;
    setIsOpen: (val: boolean) => void;
    onSubmit: (val: string) => void;
}) => {
    const [inputValue, setInputValue] = useState('');

    const handleSave = () => {
        setInputValue('');
        onSubmit(inputValue);
        setIsOpen(false);
    };

    const handleCancel = () => {
        setInputValue('');
        setIsOpen(false);
    };

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className={styles.overlay}>
            <motion.div
                className={styles.modal}
                onClick={(e) => e.stopPropagation()}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
            >
                <VStack spacing={20}>
                    <h4>Enter Swing Name</h4>
                    <Input
                        maxLength={20}
                        type="text"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        placeholder="Enter swing name"
                    />
                    <div>
                        <VStack alignment="end">
                            <HStack>
                                <Button variant="secondary" onClick={handleCancel}>
                                    Cancel
                                </Button>
                                <Button onClick={handleSave}>Save</Button>
                            </HStack>
                        </VStack>
                    </div>
                </VStack>
            </motion.div>
        </div>,
        document.body, // Render the modal outside of the root component to the body
    );
};

export default SwingNameModal;
