import { type PropsWithChildren } from 'react';

import { Button, typography } from '@common/ui';

import * as css from './Form.css';

type FormProps = {
    title: string;
    subtitle?: string;
    actionText: string;
    onSubmit(): void;
    isSubmitting?: boolean;
    error?: string;
};

const Form = ({
    title,
    subtitle,
    actionText,
    onSubmit,
    isSubmitting = false,
    error,
    children,
}: PropsWithChildren<FormProps>) => {
    return (
        <form onSubmit={onSubmit}>
            <div className={css.formContainer}>
                <h2 className={typography({ variant: 'h2' })}>{title}</h2>
                {subtitle && <p className={typography({ variant: 'p' })}>{subtitle}</p>}

                {error && (
                    <div className={css.error}>
                        <p className={typography({ variant: 'p' })}>{error}</p>
                    </div>
                )}

                {children}

                <Button type="submit" isLoading={isSubmitting}>
                    {actionText}
                </Button>
            </div>
        </form>
    );
};

export default Form;
