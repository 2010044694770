import * as css from './Spinner.css';

export function Spinner({ size = 's', isAbsolute = false }: { size: 's' | 'm' | 'l'; isAbsolute?: boolean }) {
    return (
        <div className={css.spinnerWrapper({ isAbsolute })}>
            <svg
                className={css.spinner({ size })}
                width={24}
                height={24}
                viewBox="0 0 66 66"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle className={css.path} fill="none" strokeWidth="5" strokeLinecap="round" cx="33" cy="33" r="30" />
            </svg>
        </div>
    );
}
