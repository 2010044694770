import _ from 'lodash';
import { motion } from 'motion/react';

import type { Club, ClubCollection } from '@core';
import { typography } from '@ui';

import * as css from './ClubTable.css';

export function ClubTable({
    clubs,
    selectedClubs,
    onClubClick,
}: {
    clubs: ClubCollection;
    selectedClubs: Club[];
    onClubClick: (club: Club) => void;
}) {
    return (
        <motion.div className={css.table} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            {_.map(clubs, (clubType) => (
                <div key={clubType.type_name} className={css.clubType}>
                    <h4
                        className={typography({
                            variant: 'h2',
                        })}
                    >
                        {clubType.type_name}
                    </h4>
                    <div className={css.clubs}>
                        {' '}
                        {_(clubType?.clubs)
                            .sortBy((x) => x.id)
                            .map((club) => {
                                const isSelected = _.some(selectedClubs, (selectedClub) => selectedClub.id === club.id);

                                return (
                                    <button
                                        key={club.id}
                                        className={css.club({
                                            isSelected,
                                        })}
                                        onClick={() => onClubClick(club)}
                                    >
                                        <p
                                            className={typography({
                                                variant: 'h3',
                                            })}
                                        >
                                            {club.long_name}
                                        </p>
                                        <div
                                            className={css.short({
                                                isSelected,
                                            })}
                                        >
                                            <p
                                                className={typography({
                                                    variant: 'h3',
                                                })}
                                            >
                                                {club.short_name}
                                            </p>
                                        </div>
                                    </button>
                                );
                            })
                            .value()}
                    </div>
                </div>
            ))}
        </motion.div>
    );
}
