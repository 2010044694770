import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import _ from 'lodash';
import type { Observable } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';

import {
    localize,
    isType,
    unitConversionMap,
    deepComparison,
    DEFAULT_KEY_PARAMETER_ID,
    type Nil,
    type Unit,
    type Swing,
    type UIParameter,
} from '@core';
import { ActivityNavigation, ActivitySidebar } from '@ui';
import { ImplementationOf, UIComponentValues } from '../../UIComponent.types';
import { useObservable } from '../../../../utils/hooks/useObservable';
import { setComparisonSwingByID, useGlobalStore } from '../../../../state/globalStore';
import { UserSettingsContext } from '../../../../utils/contexts/UserSettingsContext';
import SwingNameModal from '../../../modals/SwingNameModal';

import * as css from './ActivityNavigation.css';

export const ActivityNavigationImplementation: ImplementationOf<'activity_navigation'> = ({
    currentDevice,
    children,
    values$,
}) => {
    const parameterNode = _(children)
        .map((x) => x.currentNode)
        .find((x) => isType(x, 'parameter')) as UIParameter | undefined;

    const parameterID = parameterNode?.parameter?.id ?? DEFAULT_KEY_PARAMETER_ID;

    const parameterName = parameterNode?.name?.value ?? _.startCase(parameterID); // TODO: Localize

    const parameterUnit = parameterNode?.parameter?.unit ?? 'm';

    const props: Props = {
        parameterName,
        parameterID,
        parameterUnit,
        values$,
    };

    switch (currentDevice) {
        case 'kiosk':
            return <Kiosk {...props} />;
        case 'floor':
            return <Floor {...props} />;
        default:
            return <div />;
    }
};

const getDisplayValue = (parameterID: string, parameterUnit: Unit, swing: Swing | Nil) => {
    const parameter =
        swing?.fullAnalysis?.data?.analysis?.parameter_values?.[parameterID] ??
        swing?.quickAnalysis?.data?.analysis?.parameter_values?.[parameterID];

    if (parameter) {
        const p = unitConversionMap[parameter.unit](parameter.value);
        return `${p.value}${p.symbol}`;
    }

    return '— ' + unitConversionMap[parameterUnit](undefined).symbol;
};

const useEnhancedSwings = (parameterID: string, parameterUnit: Unit, values$: Observable<UIComponentValues>) =>
    useObservable(
        () =>
            values$.pipe(
                map((values) =>
                    _(values.allSwings)
                        .compact() // shallow copy with nil values removed
                        .map((swing, index, list) => ({
                            // `Swing` enhanced with value and number.
                            value: getDisplayValue(parameterID, parameterUnit, swing),
                            number: list.length - index,

                            // rest of the properties of the swing
                            ...swing,

                            // ... and then the original Swing object (for assigning to comparison swing)
                            original: swing,
                        }))
                        .value(),
                ),
                distinctUntilChanged(
                    (
                        a,
                        b, // re-render is only triggered if this returns false
                    ) =>
                        a.length === b.length &&
                        _(a)
                            .zip(b)
                            .every(
                                ([x, y]) =>
                                    // pairwise, compare only properties that can trigger a re-render
                                    x?.id === y?.id && x?.value === y?.value && x?.isFavorite === y?.isFavorite,
                            ),
                ),
            ),
        [values$, parameterID, parameterUnit],
    );

interface Props {
    readonly parameterName: string;
    readonly parameterID: string;
    readonly parameterUnit: Unit;
    readonly values$: Observable<UIComponentValues>;
}

function Kiosk({ parameterName, parameterID, parameterUnit, values$ }: Props) {
    const navigate = useNavigate();
    const enhancedSwings = useEnhancedSwings(parameterID, parameterUnit, values$);
    const { markSwingAsFavorite } = useContext(UserSettingsContext);
    const [showSwingNameModal, setShowSwingNameModal] = useState(false);
    const [tempSwingId, setTempSwingId] = useState<number | null>(null);

    const [selectedSwingAnalysisID, comparisonSwingAnalysisID, setValues] = useObservable(
        () =>
            values$.pipe(
                map((values) => [values.swing?.id, values.comparisonSwing?.id, values.setValues] as const),
                distinctUntilChanged(deepComparison),
            ),
        [values$],
    );

    const onToggleComparison = (val: boolean, id: number) => {
        const swing = _.find(enhancedSwings, (s) => s.id === id)?.original;

        if (!val) {
            return setComparisonSwingByID(null);
        }

        setValues?.({
            comparisonSwing: swing,
        });
    };

    const onToggleFavorite = (val: boolean, id: number) => {
        if (val) {
            setTempSwingId(id);
            setShowSwingNameModal(true);
        } else {
            markSwingAsFavorite({ id, name: null });
        }
    };

    const onSwingNameSubmit = (name: string) => {
        if (tempSwingId) {
            markSwingAsFavorite({ id: tempSwingId, name });

            setTempSwingId(null);
        }
    };

    const onSelect = (swing: Swing) => {
        setValues?.({ swing });
    };

    return (
        <>
            <div className={css.layout}>
                <ActivityNavigation
                    heading={localize('activity_navigation.header')}
                    onToggleFavorite={onToggleFavorite}
                    onToggleComparison={onToggleComparison}
                    onSelectSwing={onSelect}
                    swings={enhancedSwings}
                    selectedKeyParameter={parameterName}
                    selectedSwingId={selectedSwingAnalysisID}
                    comparisonSwingId={comparisonSwingAnalysisID}
                    onClickKeyParameter={() => {
                        // Navigate to LayoutEditor page and open the key-parameter modal.
                        navigate('layout-editor', { state: { isEditingKeyParameter: true } });
                    }}
                    debug={false}
                />
            </div>
            <SwingNameModal
                isOpen={showSwingNameModal}
                setIsOpen={(val) => setShowSwingNameModal(val)}
                onSubmit={(name) => onSwingNameSubmit(name)}
            />
        </>
    );
}

function Floor({ parameterName, parameterID, parameterUnit, values$ }: Props) {
    const defaultDisplayValue = getDisplayValue(parameterID, parameterUnit, null);
    const enhancedSwings = useEnhancedSwings(parameterID, parameterUnit, values$);

    const isAnalyzing = useGlobalStore((state) => state.isAnalyzing);

    return (
        <ActivitySidebar
            swings={enhancedSwings}
            isAnalyzing={isAnalyzing}
            fallbackDisplayValue={defaultDisplayValue}
            selectedKeyParameter={parameterName}
        />
    );
}
