import _ from 'lodash';
import { Suspense, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import { Flex, Box } from '@react-three/flex';
import { Plane, Text, OrthographicCamera } from '@react-three/drei';
import useMeasure, { type RectReadOnly } from 'react-use-measure';
import {
    CaretLeft as CaretLeftIcon,
    CaretRight as CaretRightIcon,
    Play as PlayIcon,
    Pause as PauseIcon,
} from '@phosphor-icons/react';

import { typography } from '@common/ui';
import type { StatusPaneProps } from './VideoPlayback.types';
import { constants, localize } from '@common';
import type { Analysis, Nil, PositionNumber, Segmentation } from '@common';
import { useVideoTexture } from '../../utils/hooks/useVideoTexture';
import { type CameraAngles } from '../../utils/types/camera';
import PlaybackScrubber from './PlaybackScrubber/PlaybackScrubber';
import { useVideoPlaybackStore } from './VideoPlayback.state';
import { FRONTEND_VIDEO_HEIGHT, FRONTEND_VIDEO_WIDTH } from '../../utils/consts';

import * as css from './VideoPlayback.css';

const StatusPane = ({ text = 'No swing selected', width, height }: StatusPaneProps) => {
    return (
        <mesh>
            <planeGeometry args={[width, height]} />
            <meshBasicMaterial color="#040810" />
            <Text fontWeight="bold" color="#94A3B8" fontSize={12}>
                {text}
            </Text>
        </mesh>
    );
};

type SceneProps = {
    textureUrl: string;
    maxDuration: number;
    scale?: number;
    cameraAngle: CameraAngles;
    isComparison?: boolean;
    segmentationOffset: SegmentationOffset;
};

function Scene({
    textureUrl,
    maxDuration,
    scale = 1,
    cameraAngle = 'face_on',
    isComparison,
    segmentationOffset,
}: SceneProps) {
    const videoTexture = useVideoTexture(textureUrl, {
        start: false,
        preload: 'auto',
        crossOrigin: 'anonymous',
    });

    useLayoutEffect(() => {
        const unsubscribe = useVideoPlaybackStore.subscribe(
            (state) => state.replayProgress,
            (progress) => {
                if (!videoTexture.source?.dataReady) {
                    return;
                }

                const offset = isComparison ? segmentationOffset.difference / constants.VIDEO_FPS : 0;

                const seekTo = progress * maxDuration - offset;
                const duration = videoTexture.source.data.duration;

                if (seekTo < duration) {
                    videoTexture.source.data.currentTime = seekTo;
                }
            },
        );

        return () => {
            unsubscribe();
        };
    }, [videoTexture, isComparison, segmentationOffset, maxDuration]);

    return (
        <group scale={scale}>
            <Plane args={[FRONTEND_VIDEO_WIDTH, FRONTEND_VIDEO_HEIGHT, 1, 1]}>
                <Text
                    fontWeight="bold"
                    color="#fff"
                    anchorX="left"
                    anchorY="top"
                    fontSize={18}
                    position={[-FRONTEND_VIDEO_WIDTH / 2 + 24, FRONTEND_VIDEO_HEIGHT / 2 - 24, 0]}
                >
                    {isComparison ? 'COMPARISON' : 'CURRENT'}
                </Text>
                <Text
                    fontWeight="bold"
                    color="#fff"
                    anchorX="left"
                    anchorY="top"
                    fontSize={28}
                    position={[-FRONTEND_VIDEO_WIDTH / 2 + 24, FRONTEND_VIDEO_HEIGHT / 2 - 48, 0]}
                >
                    {localize(`camera_angle.${cameraAngle}`)}
                </Text>
                <meshBasicMaterial map={videoTexture} />
            </Plane>
        </group>
    );
}

type SegmentationOffset = {
    current: number;
    comparison: number;
    difference: number;
};

type RendererProps = {
    activeSwingAnalysis: Analysis;
    comparisonSwingAnalysis: Analysis | Nil;
    segmentationOffset: SegmentationOffset;
    activeCameraAngle: CameraAngles | null;
    scale: number;
    showComparison: boolean;
    maxTotalFrames: number;
};

function compareSegmentations(
    currentSegmentation: Segmentation,
    comparisonSegmentation?: Segmentation,
): Record<keyof Segmentation, SegmentationOffset> {
    // Filter out any positions where either value is null
    const validPositions = _.pickBy(
        currentSegmentation,
        (value, key) => _.isNumber(value) && _.isNumber(comparisonSegmentation?.[key as keyof Segmentation]),
    ) as Record<keyof Segmentation, number>;

    // Create the comparison object for valid positions
    return _.mapValues(validPositions, (currentValue, key) => {
        const comparisonValue = comparisonSegmentation![key as `P${PositionNumber}`];
        return {
            current: currentValue,
            comparison: comparisonValue,
            difference: currentValue - comparisonValue,
        };
    });
}

function Renderer({
    activeSwingAnalysis,
    comparisonSwingAnalysis,
    segmentationOffset,
    activeCameraAngle,
    scale = 1,
    showComparison,
    maxTotalFrames,
}: RendererProps) {
    const { height: canvasHeight, width: canvasWidth } = useThree((state) => state.viewport);

    const cameraAngles: CameraAngles[] = showComparison
        ? ['face_on', 'face_on', 'down_the_line', 'down_the_line']
        : ['back', 'down_the_line', 'face_on', 'trail_front'];

    const videos = activeSwingAnalysis.data.videos!;
    const comparisonVideos = comparisonSwingAnalysis?.data.videos;
    const isFullscreen = Boolean(activeCameraAngle) && !showComparison;

    const effectiveCameraAngles = isFullscreen ? [activeCameraAngle] : cameraAngles;

    return (
        <Flex
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="space-evenly"
            alignItems="flex-start"
            height={canvasHeight}
            width={canvasWidth}
            position={[-canvasWidth / 2, canvasHeight / 2, -1000]}
            plane="xy"
        >
            {_.map(effectiveCameraAngles, (angle, index) => {
                const isComparison = showComparison && index % 2 === 1;
                const videosForAngle = isComparison ? comparisonVideos : videos;
                const isValid = Boolean(videosForAngle?.[index]);
                const textureUrl = _.find(videosForAngle!, (video) => video.name === angle)?.url ?? '';
                const paneWidth = isFullscreen ? canvasWidth : canvasWidth / 2;
                const paneHeight = isFullscreen ? canvasHeight : canvasHeight / 2;
                return (
                    <Box
                        height={paneHeight}
                        width={paneWidth}
                        centerAnchor
                        align="center"
                        justify="center"
                        justifyContent="center"
                    >
                        <Box name="auto-sized-suspense-wrap">
                            <Suspense
                                fallback={<StatusPane text="Loading video" height={paneHeight} width={paneWidth} />}
                            >
                                {isValid ? (
                                    <Scene
                                        textureUrl={textureUrl}
                                        maxDuration={maxTotalFrames / constants.VIDEO_FPS}
                                        cameraAngle={angle!}
                                        scale={scale}
                                        isComparison={isComparison}
                                        segmentationOffset={segmentationOffset}
                                    />
                                ) : (
                                    <StatusPane text="No swing selected" height={paneHeight} width={paneWidth} />
                                )}
                            </Suspense>
                        </Box>
                    </Box>
                );
            })}
        </Flex>
    );
}

type VideoPlaybackProps = {
    activeSwingAnalysis: Analysis;
    comparisonSwingAnalysis?: Analysis | Nil;
    displayMode: 'comparison' | 'playback';
};

const calculatePlaybackSceneScale = (bounds: RectReadOnly, videosPerRow: number) => {
    const maxVideoWidth = bounds.width / videosPerRow;
    const maxVideoHeight = bounds.height / videosPerRow;

    const scaleByWidth = maxVideoWidth / FRONTEND_VIDEO_WIDTH;
    const scaleByHeight = maxVideoHeight / FRONTEND_VIDEO_HEIGHT;

    return Math.min(scaleByWidth, scaleByHeight);
};

export function VideoPlayback({ activeSwingAnalysis, comparisonSwingAnalysis, displayMode }: VideoPlaybackProps) {
    const [syncedPosition, setSyncedPosition] = useState<keyof Segmentation>('P1');
    const activeCameraAngle = useVideoPlaybackStore((state) => state.activeCameraAngle);
    const autoPlaying = useVideoPlaybackStore((state) => state.autoPlaying);
    const speed = useVideoPlaybackStore((state) => state.playbackSpeed);
    const handlerMotionValue = useVideoPlaybackStore((state) => state.handlerMotionValue);
    const travelDistance = useVideoPlaybackStore((state) => state.travelDistance);

    const swingPositions = _.map(constants.SWING_POSITIONS, (pos) => pos.toUpperCase());
    const isFullscreen = Boolean(activeCameraAngle) && displayMode === 'playback';

    const [ref, bounds] = useMeasure();

    const videosPerRow = isFullscreen ? 1 : 2;

    const scale = useMemo(() => calculatePlaybackSceneScale(bounds, videosPerRow), [bounds, videosPerRow]);

    const segmentationDiff = useMemo(
        () =>
            compareSegmentations(
                activeSwingAnalysis.data.analysis!.segmentation,
                comparisonSwingAnalysis?.data.analysis?.segmentation,
            ),
        [activeSwingAnalysis, comparisonSwingAnalysis],
    );

    const videos = activeSwingAnalysis.data.videos!;
    const comparisonVideos = comparisonSwingAnalysis?.data.videos;
    const isComparison = displayMode === 'comparison' && Boolean(comparisonVideos?.length);

    const [maxTotalFrames, singleFrameStep] = useMemo(() => {
        const max = Math.max(
            ..._.compact(
                _.map(
                    _.flatten([videos, isComparison ? comparisonVideos! : []]),
                    (video) => video.metadata.totalFrames,
                ),
            ),
        );

        const step = 1.01 / max; // Stepping "1.01" frames minimum because of a Safari quirk.

        return [max, step];
    }, [videos, comparisonVideos, isComparison]);

    const validPositions = _.keys(segmentationDiff);
    const segmentationOffset = segmentationDiff[syncedPosition];

    const handleFrameByFrame = useCallback(
        (direction: 1 | -1) => {
            useVideoPlaybackStore.setState((state) => {
                const nextProgress = state.replayProgress + singleFrameStep * Math.sign(direction);
                const replayProgress = Math.min(Math.max(nextProgress, 0), 1);

                state.handlerMotionValue.set(replayProgress * state.travelDistance);

                return {
                    autoPlaying: false,
                    replayProgress,
                };
            });
        },
        [singleFrameStep],
    );

    const handleSyncToPosition = (position: keyof Segmentation) => {
        setSyncedPosition(position);

        const totalFrames = maxTotalFrames;
        const targetFrame = segmentationDiff[position];

        if (totalFrames && targetFrame.current) {
            const val = targetFrame.current / totalFrames;
            const TEMP_TIMEOUT = 50;

            setTimeout(() => {
                handlerMotionValue.set(val * travelDistance);
            }, TEMP_TIMEOUT);
        }
    };

    const handleJumpToPosition = (position: keyof Segmentation) => {
        const segmentation = activeSwingAnalysis.data.analysis?.segmentation;
        const totalFrames = activeSwingAnalysis.data?.videos?.[0]?.metadata?.totalFrames;

        if (!segmentation || !segmentation[position]) {
            console.error(`Can't jump to position ${position}: Missing segmentation.`);
            return;
        }

        if (!totalFrames) {
            console.error(`Can't jump to position ${position}: Missing video metadata.`);
            return;
        }

        const progress = ~~segmentation[position] / totalFrames;

        useVideoPlaybackStore.setState(({ handlerMotionValue, travelDistance }) => {
            handlerMotionValue.set(progress * travelDistance);

            return {
                autoPlaying: false,
            };
        });
    };

    const handleTogglingAutoPlay = () => {
        useVideoPlaybackStore.setState((prev) => ({
            autoPlaying: !prev.autoPlaying,
        }));
    };

    const renderUIBydisplayMode = useMemo(() => {
        if (displayMode === 'comparison') {
            return (
                <div className={css.controlsWrapper}>
                    <div className={css.controls}>
                        <button className={css.button({ isActive: false })} onClick={() => handleFrameByFrame(-1)}>
                            <CaretLeftIcon size={20} />
                        </button>
                        <button className={css.button({ isActive: autoPlaying })} onClick={handleTogglingAutoPlay}>
                            {autoPlaying ? <PauseIcon size={18} /> : <PlayIcon size={18} />}
                        </button>
                        <button className={css.button({ isActive: false })} onClick={() => handleFrameByFrame(1)}>
                            <CaretRightIcon size={20} />
                        </button>
                    </div>
                    <div className={css.controls}>
                        {_.map(swingPositions, (pos) => {
                            const isValid = _.includes(validPositions, pos);
                            return (
                                <button
                                    key={pos}
                                    className={css.button({ isActive: pos === syncedPosition })}
                                    disabled={!isValid}
                                    onClick={() =>
                                        isValid && handleSyncToPosition(pos as unknown as keyof Segmentation)
                                    }
                                >
                                    <span
                                        style={{
                                            opacity: isValid ? 1 : 1 / 3,
                                        }}
                                        className={typography({ variant: 'h3' })}
                                    >
                                        {pos}
                                    </span>
                                </button>
                            );
                        })}
                    </div>
                </div>
            );
        }

        if (displayMode === 'playback') {
            return (
                <div className={css.controlsWrapper}>
                    <div className={css.controls}>
                        <button className={css.button({ isActive: false })} onClick={() => handleFrameByFrame(-1)}>
                            <CaretLeftIcon size={20} />
                        </button>
                        <button
                            className={css.button({ isActive: autoPlaying })}
                            onClick={() =>
                                useVideoPlaybackStore.setState((prev) => ({ autoPlaying: !prev.autoPlaying }))
                            }
                        >
                            {autoPlaying ? <PauseIcon size={18} /> : <PlayIcon size={18} />}
                        </button>
                        <button className={css.button({ isActive: false })} onClick={() => handleFrameByFrame(1)}>
                            <CaretRightIcon size={20} />
                        </button>
                    </div>

                    <div className={css.controls}>
                        {_.at(constants.SWING_POSITIONS, constants.KEY_POSITION_INDICES)
                            .map((pos) => pos.toUpperCase())
                            .map((pos) => {
                                return (
                                    <button
                                        key={pos}
                                        className={css.button({ isActive: false })}
                                        disabled={false}
                                        onClick={() => handleJumpToPosition(pos as keyof Segmentation)}
                                    >
                                        <span className={typography({ variant: 'h3' })}>{pos}</span>
                                    </button>
                                );
                            })}
                    </div>
                </div>
            );
        }

        return null;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayMode, segmentationDiff, syncedPosition, autoPlaying]);

    if (!activeSwingAnalysis.data.videos) {
        return <div className={css.emptyState}></div>;
    }

    return (
        <>
            <Canvas
                ref={ref}
                onClick={handleTogglingAutoPlay}
                flat
                dpr={[1, 2]}
                shadows={false}
                style={{
                    borderRadius: 8,
                    position: 'relative',
                    width: '100%',
                    height: 'auto',
                    aspectRatio: `${FRONTEND_VIDEO_WIDTH} / ${FRONTEND_VIDEO_HEIGHT}`,
                }}
            >
                <OrthographicCamera makeDefault far={100000} near={0.00001} zoom={1}>
                    <Renderer
                        activeSwingAnalysis={activeSwingAnalysis}
                        comparisonSwingAnalysis={comparisonSwingAnalysis}
                        scale={scale}
                        segmentationOffset={segmentationOffset}
                        activeCameraAngle={activeCameraAngle}
                        showComparison={displayMode === 'comparison'}
                        maxTotalFrames={maxTotalFrames}
                    />
                </OrthographicCamera>
            </Canvas>
            {renderUIBydisplayMode}
            <PlaybackScrubber
                segmentationOffset={segmentationOffset}
                speed={speed}
                showComparison={displayMode === 'comparison'}
                activeSwingAnalysis={activeSwingAnalysis}
                comparisonSwingAnalysis={comparisonSwingAnalysis}
            />
        </>
    );
}
