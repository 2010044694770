import { Observable } from 'rxjs';
import _ from 'lodash';

import UIComponent from '../UIComponents/UIComponent';
import { Device, interpolateChildDisplayOrder, interpolateShowChildOn, Layout, UINodeTree } from '@core';
import { IS_DEV } from '../../utils/consts';
import ElvaLogo from '../Logo/ElvaLogo';
import { UIComponentValues } from '../UIComponents/UIComponent.types';

import * as css from './UIComponentsLayout.css';

type UIComponentsLayoutProps = {
    uiNodeTree: UINodeTree;
    layout?: Layout;
    currentDevice: Device;
    values$: Observable<UIComponentValues>;
};

export default function UIComponentsLayout({ uiNodeTree, layout, currentDevice, values$ }: UIComponentsLayoutProps) {
    if (_.isEmpty(uiNodeTree)) {
        console.error('UIComponentsLayout: uiNodeTree is empty or missing', uiNodeTree);
        return IS_DEV ? <div>UIComponentsLayout: uiNodeTree is empty or missing</div> : <> </>;
    }
    if (_.isEmpty(layout?.customizations)) {
        console.error('UIComponentsLayout: missing layout customizations', layout?.customizations);
        return IS_DEV ? <div>UIComponentsLayout: missing layout customizations</div> : <> </>;
    }

    const rootProps = _(layout!.customizations)
        .filter(
            (c) =>
                c &&
                _.isNil(c.parent_ui_node_id) &&
                _.includes(interpolateShowChildOn(c, uiNodeTree.relations, uiNodeTree.nodes), currentDevice),
        )
        .sortBy((c) => interpolateChildDisplayOrder(c, uiNodeTree.relations))
        .map((c) => {
            const currentNode = _.find(uiNodeTree.nodes, (n) => n.id === c.child_ui_node_id);
            return (
                currentNode && {
                    currentNode,
                    metadataOverride: c.child_metadata,
                    deviceCount: c.show_child_on?.length,
                }
            );
        })
        .compact()
        .value();

    const activityNavigationProps = _.maxBy(
        _.remove(rootProps, (p) => p.currentNode.type === 'activity_navigation'),
        (p) => p.deviceCount,
    );

    if (!activityNavigationProps) console.warn('UIComponentsLayout: missing activity_navigation node', uiNodeTree);

    return currentDevice === 'kiosk' ? (
        <div className={css.layout}>
            {activityNavigationProps && (
                <UIComponent
                    uiNodeTree={uiNodeTree}
                    layout={layout}
                    currentDevice={currentDevice}
                    currentNode={activityNavigationProps.currentNode}
                    metadataOverride={activityNavigationProps.metadataOverride}
                    values$={values$}
                />
            )}
            <div className={css.columnLayout}>
                {_.map(rootProps, ({ currentNode, metadataOverride }) => (
                    <UIComponent
                        key={currentNode.id}
                        uiNodeTree={uiNodeTree}
                        layout={layout}
                        currentDevice={currentDevice}
                        currentNode={currentNode}
                        metadataOverride={metadataOverride}
                        values$={values$}
                    />
                ))}
            </div>
        </div>
    ) : (
        <div className={css.floor.layout}>
            <div className={css.floor.sidebar}>
                <div className={css.floor.sidebarWrapper}>
                    {activityNavigationProps && (
                        <UIComponent
                            uiNodeTree={uiNodeTree}
                            layout={layout}
                            currentDevice={currentDevice}
                            currentNode={activityNavigationProps.currentNode}
                            metadataOverride={activityNavigationProps.metadataOverride}
                            values$={values$}
                        />
                    )}
                    <div className={css.floor.logo}>
                        <ElvaLogo width={112} />
                    </div>
                </div>
            </div>
            <div className={css.floor.modulesWrapper}>
                {_.map(rootProps, ({ currentNode, metadataOverride }) => (
                    <UIComponent
                        key={currentNode.id}
                        uiNodeTree={uiNodeTree}
                        layout={layout}
                        currentDevice={currentDevice}
                        currentNode={currentNode}
                        metadataOverride={metadataOverride}
                        values$={values$}
                    />
                ))}
            </div>
        </div>
    );
}
