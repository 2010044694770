import { useMemo, useState } from 'react';

import { colors, Spinner, typography } from '@common/ui';
import { formatDateFromEpoch } from '@common';
import { Club } from '../../../components/icons/Club';
import { Comparison } from '../../../components/icons/Comparison';
import { SearchInput } from '../../../components/SearchInput/SearchInput';
import { ComparisonSidebarProps, SwingCardProps, SwingsListSectionProps } from './ComparisonSidebar.types';
import { useGlobalStore } from '../../../state/globalStore';

import * as css from './ComparisonSidebar.css';
import { useActivityContext } from 'apps/booth/src/utils/contexts/ActivityContext';
import DataManager from 'apps/booth/src/DataManager/DataManager';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';

export const SwingsListSection = ({
    title,
    swings,
    activities,
    selectedComparisonSwingId,
    onNewSwingSelected,
    isLoading = false,
    currentActivityId,
}: SwingsListSectionProps) => {
    return (
        <>
            <h3
                className={typography({
                    variant: 'h4',
                })}
            >
                {title}
            </h3>
            <div className={css.list}>
                {isLoading && <Spinner size="s" />}
                {swings?.map((s, index) => {
                    const club = activities?.find((a) => a.id === s.activityID)?.club?.short_name;
                    const tags = activities?.find((a) => a.id === s.activityID)?.tags?.map((t) => t.name);
                    const title = s?.name || `Swing #${s?.id}`;
                    const isDisabled = currentActivityId === s.id;
                    const isSelected = s.id === selectedComparisonSwingId;

                    return (
                        <SwingCard
                            key={index}
                            disabled={isDisabled}
                            date={s.lastUpdated}
                            title={title}
                            club={club}
                            tags={tags}
                            isSelected={isSelected}
                            onClick={() => onNewSwingSelected(s.id)}
                        />
                    );
                })}
            </div>
        </>
    );
};

function SwingCard({ date, title, club, tags, isSelected, onClick, disabled }: SwingCardProps) {
    const formattedDate = formatDateFromEpoch(date);

    return (
        <button
            onClick={() => {
                if (!disabled) {
                    onClick();
                }
            }}
        >
            <div className={css.swingCard({ isSelected })}>
                <div className={css.swingCardTop}>
                    <div>
                        <p
                            className={typography({
                                variant: 'h4',
                            })}
                        >
                            {formattedDate}
                        </p>
                        <p
                            className={typography({
                                variant: 'h3',
                            })}
                        >
                            {title}
                        </p>
                    </div>
                    <Comparison color={isSelected ? colors.orange[500] : colors.bluegray[500]} />
                </div>
                <div className={css.swingCardTags}>
                    {club && <div className={css.swingCardTag}>{club}</div>}
                    {tags?.map((tag) => (
                        <div key={tag} className={css.swingCardTag}>
                            {tag}
                        </div>
                    ))}
                </div>
            </div>
        </button>
    );
}

export function ComparisonSidebar({
    favoriteSwings,
    currentActivityId,
    activities,
    onNewSwingSelected,
    initialSelectedComparisonSwingId = -1,
}: ComparisonSidebarProps) {
    //const [activeSwingList, setActiveSwingList] = useState<'my-swings' | 'elva-swings'>('my-swings');
    const [filter, setFilter] = useState<string>('');

    // Split favorites into current activity and all other favorites
    const currentActivityFavoriteSwings = _.filter(favoriteSwings, (s) => s.activityID === currentActivityId);
    const otherActivitiesFavoriteSwings = _.filter(favoriteSwings, (s) => s.activityID !== currentActivityId);

    const [selectedComparisonSwingId, setSelectedSwingId] = useState(initialSelectedComparisonSwingId);
    const numberOfFavoriteSwings = favoriteSwings.length;

    const filteredCurrentActivitySwings = useMemo(
        () =>
            currentActivityFavoriteSwings?.filter(
                (swing) => swing?.name?.toLowerCase().includes(filter.toLowerCase()),
            ) || [],
        [filter, currentActivityFavoriteSwings],
    );

    const filteredOtherActivitySwings = useMemo(
        () =>
            otherActivitiesFavoriteSwings?.filter(
                (swing) => swing?.name?.toLowerCase().includes(filter.toLowerCase()),
            ) || [],
        [filter, otherActivitiesFavoriteSwings],
    );

    return (
        <div className={css.root}>
            <div className={css.filterBox}>
                <h3
                    className={typography({
                        variant: 'h2',
                    })}
                >
                    Comparison swing
                </h3>
                <div className={css.filter}>
                    <p
                        className={typography({
                            variant: 'h4',
                        })}
                    >
                        Filter swings
                    </p>
                    <div className={css.fields}>
                        <SearchInput value={filter} onChange={setFilter} />
                        <button className={css.clubButton}>
                            <Club />
                            Club
                        </button>
                    </div>
                </div>
            </div>
            <div className={css.swingPicker}>
                <div className={css.swingPickerItem}>
                    {/* <Switch
                        values={['My swings', 'Elva swings']}
                        keys={['my-swings', 'elva-swings']}
                        alignSelf="center"
                        onClick={(activeValue) => {
                            setActiveSwingList(activeValue as 'my-swings' | 'elva-swings');
                        }}
                    /> */}
                    <div className={css.listInfo}>
                        <p
                            className={typography({
                                variant: 'p',
                            })}
                        >
                            {numberOfFavoriteSwings} swings
                        </p>
                        <p
                            className={typography({
                                variant: 'p',
                            })}
                        >
                            All clubs
                        </p>
                    </div>
                </div>
                <div className={css.swingPickerItem}>
                    <SwingsListSection
                        currentActivityId={currentActivityId}
                        title={'Current activity'}
                        swings={filteredCurrentActivitySwings}
                        activities={activities}
                        selectedComparisonSwingId={selectedComparisonSwingId}
                        onNewSwingSelected={(id) => {
                            setSelectedSwingId(id);
                            onNewSwingSelected(id);
                        }}
                    />
                </div>
                <div className={css.swingPickerItem}>
                    <SwingsListSection
                        currentActivityId={currentActivityId}
                        title={'Your Swings'}
                        swings={filteredOtherActivitySwings}
                        activities={activities}
                        selectedComparisonSwingId={selectedComparisonSwingId}
                        onNewSwingSelected={(id) => {
                            setSelectedSwingId(id);
                            onNewSwingSelected(id);
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
