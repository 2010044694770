import _ from 'lodash';
import { SupabaseClient } from '@supabase/supabase-js';

import { convertToSimplifiedConfig } from '../utils/hooks/useSupabaseCameraConfig/utils';
import type { CameraConfig, SimplifiedCameraConfig } from '../utils/types/camera';
import type { Activity, Supabase } from '@common';
import {
    CommunicationMessage,
    fetchAllSwingsForActivity,
    isFiniteNumber,
    fetchAllUserActivities,
    createNewActivity,
    getActivityTags,
    getActivityClub,
    fetchAllUserFavoriteSwings,
} from '@common';
import { useCommunicationSocketStore } from '../state/communicationSocketStore';
import { FullAnalysisPayload } from './DataManager.types';

interface DataManagerConfig {
    supabase: SupabaseClient;
    userId: string;
    boothSessionId: number;
}

class DataManager {
    private static instance: DataManager | null = null;

    private readonly userId: string;
    private readonly boothSessionId: number;
    private readonly supabase: Supabase;
    private intervalId: NodeJS.Timeout | null = null;
    private communicationSubscription: (() => void) | null = null;

    public cameraConfig?: SimplifiedCameraConfig;
    public storageBaseUrl: string;

    private constructor(config: DataManagerConfig) {
        this.userId = config.userId;
        this.boothSessionId = config.boothSessionId;
        this.supabase = config.supabase;
        this.storageBaseUrl = (this.supabase.storage as any).url;
    }

    private async init(): Promise<void> {
        try {
            this.cameraConfig = await this.getCameraConfig();
        } catch (error) {
            if (error instanceof Error) {
                throw new Error(`Initialization failed: ${error.message}`);
            }
            throw error;
        }
    }

    public async getCurrentActivityId() {
        let activityId = await this.getActivityId(this.boothSessionId);

        // Create a new activity and sent the activityId to the beast
        if (activityId === -1) {
            activityId = await this.createNewActivity();
        }

        if (!isFiniteNumber(activityId)) {
            throw new Error('Invalid activity ID received');
        }

        return activityId;
    }

    public async getAnalysisJsonDumpById(id: number) {
        const { data, error } = await this.supabase.rpc('get_analysis_json_dump', {
            _analysis_id: id,
        });

        if (error) {
            return null;
        }
        return data;
    }

    public fetchAllUserActivities() {
        return fetchAllUserActivities(this.supabase as SupabaseClient, this.userId);
    }

    public subscribeToFullAnalysis(handler: (payload: FullAnalysisPayload) => Promise<void>) {
        return this.supabase
            .channel('channel')
            .on(
                'postgres_changes',
                {
                    event: 'UPDATE',
                    schema: 'public',
                    table: 'full_analysis_job_queue',
                },
                async (payload) => {
                    try {
                        await handler(payload as unknown as FullAnalysisPayload);
                    } catch (error) {
                        console.error('Error processing change:', error);
                    }
                },
            )
            .subscribe((status) => {
                if (status === 'CLOSED' || status === 'CHANNEL_ERROR') {
                    console.error('Subscription error:', status);
                }
            });
    }

    private async getCameraConfig(): Promise<SimplifiedCameraConfig> {
        const { data, error } = await this.supabase
            .from('booth_sessions')
            .select('camera_calibrations (*)')
            .eq('id', this.boothSessionId)
            .order('id', { ascending: false })
            .limit(1)
            .single();

        if (error) {
            throw error;
        }

        return convertToSimplifiedConfig(
            (
                data.camera_calibrations as unknown as {
                    calibration: CameraConfig;
                }
            ).calibration,
        );
    }

    public async getAnalysisId(swingId: number): Promise<number | null> {
        const { data, error } = await this.supabase
            .from('swing_analysis_link_view')
            .select('full_analysis_id')
            .eq('swing_id', swingId)
            .single();

        if (error || !data.full_analysis_id) {
            return null;
        }

        return data.full_analysis_id;
    }

    private async createNewActivity(): Promise<number> {
        const activityId = await createNewActivity(this.supabase as SupabaseClient, this.userId, this.boothSessionId);
        return activityId ?? -1;
    }

    private async getActivityId(boothSessionId: number): Promise<number> {
        const { data, error } = await this.supabase
            .from('activities')
            .select('*')
            .eq('booth_session_id', boothSessionId)
            .order('start_time', { ascending: false })
            .limit(1)
            .single();

        if (error || !data?.id) {
            console.error(error);
        }

        return data?.id ?? -1;
    }

    public static getInstance(): DataManager {
        if (!DataManager.instance) {
            throw new Error('DataManager must be initialized first');
        }
        return DataManager.instance;
    }

    public static async initialize(config: DataManagerConfig): Promise<DataManager> {
        try {
            const {
                data: { user },
            } = await config.supabase.auth.getUser();

            if (!user) {
                throw new Error('No authenticated user found');
            }

            if (DataManager.instance && DataManager.instance.boothSessionId !== config.boothSessionId) {
                await DataManager.disconnect();
                DataManager.instance = null;
            }

            if (!DataManager.instance) {
                DataManager.instance = new DataManager(config);
                await DataManager.instance.init();
            }

            return DataManager.instance;
        } catch (error) {
            if (error instanceof Error) {
                throw new Error(`Failed to initialize DataManager: ${error.message}`);
            }
            throw error;
        }
    }

    public static async disconnect(): Promise<void> {
        if (!DataManager.instance) {
            return;
        }

        if (DataManager.instance.intervalId) {
            clearInterval(DataManager.instance.intervalId);
            DataManager.instance.intervalId = null;
        }

        try {
            // Cleanup subscriptions
            if (DataManager.instance.communicationSubscription) {
                DataManager.instance.communicationSubscription();
            }

            // Reset beast state
            useCommunicationSocketStore.getState().actions.sendCommunicationMessage({
                type: CommunicationMessage.INSTRUCTION,
                content: { action: 'GO_IDLE' },
            });

            // Clear instance
            DataManager.instance = null;
        } catch (error) {
            console.error('Error during DataManager disconnect:', error);
            throw error;
        }
    }

    public async getActivity(activityId?: number): Promise<Activity | null> {
        if (!activityId) return null;

        const { data, error } = await this.supabase.from('activities').select('*').eq('id', activityId).single();

        if (error) throw error;

        return data;
    }

    public async getSwings(activityId: number) {
        return fetchAllSwingsForActivity(this.supabase as SupabaseClient, activityId);
    }

    public async getFavoriteSwings() {
        return fetchAllUserFavoriteSwings(this.supabase as SupabaseClient, this.userId);
    }

    public async getClubs() {
        const { data, error } = await this.supabase.from('club_types').select('type_name, clubs(*)');

        if (error) {
            console.error('Failed to fetch clubs:', error);
            return [];
        }

        return data;
    }

    public async getClubsAndTags(activityId: number) {
        if (!activityId) return { activityClub: null, activityTags: null };

        const [activityClub, activityTags] = await Promise.all([
            getActivityClub(this.supabase as SupabaseClient, activityId),
            getActivityTags(this.supabase as SupabaseClient, activityId),
        ]);

        return { activityClub, activityTags };
    }

    public static reset(): void {
        DataManager.instance = null;
    }
}

export default DataManager;
