import { Fragment, useEffect } from 'react';
import _ from 'lodash';

import TopBar from '../components/TopBar';
import { useUIComponentValues, useGlobalStore } from '../state/globalStore';
import { VideoReplay } from '../components/VideoReplay/VideoReplay.floor';
import { useUserSettingsStore } from '../state/userSettingsStore';
import { IS_DEV } from '../utils/consts';
import UIComponentsLayout from '../components/UIComponentsLayout';
import { ScreenTitle } from '../components/ScreenTitle';
import { useRelaySocketStore } from '../state/relaySocketStore';

import * as css from './FloorScreen.css';
import { useBeastSocketConnection } from '../utils/hooks/useBeastSocketConnection';
import { BeastListener } from '../realtime/BeastListener';
import { FullAnalysisListener } from '../realtime/FullAnalysisListener';
import { useBoothSession } from '../utils/hooks/useBoothSession';
import { useActivityContext } from '../utils/contexts/ActivityContext/ActivityContext';

export default function FloorScreen() {
    const { boothSessionDetails } = useBoothSession();
    const { showFloorActivity } = useActivityContext();
    const hasEndpoints = boothSessionDetails?.beastEndpoint && boothSessionDetails.relayEndpoint;
    const userSettings = useUserSettingsStore(
        (state) =>
            [
                state.activeLayout,
                state.activityClub,
                state.uiNodeTree,
                state.playbackSettings,
                state.actions.setUserSettings,
            ] as const,
    );

    const syncPayload = useRelaySocketStore(
        (state) =>
            [
                state.payload?.activeLayout,
                state.payload?.activityClub,
                state.payload?.activityTags,
                state.payload?.playbackSettings,
                state.payload?.userSettings,
                state.payload?.activityId,
            ] as const,
    );

    const [cloudLayout, cloudClub, uiNodeTree, userPlaybackSettings, setDeprecatedUserJSONSettings] = userSettings;
    const [layout, club, tags, playbackSettings, deprecatedUserJSONSettings, activityId = -1] = syncPayload;

    // Disable on dev.
    const shouldInstantReplay = true; //TODO: !IS_DEV;
    const instantReplay = useGlobalStore((state) => state.instantReplay);
    const values$ = useUIComponentValues();

    const activeLayout = layout || cloudLayout;
    const selectedClub = club || cloudClub;

    useEffect(() => {
        // This effect syncs incoming `userSettings` websocket message with the local `userSettings` store.
        // Save these user preferences to _user layout_ table instead.
        if (deprecatedUserJSONSettings) {
            setDeprecatedUserJSONSettings({ showCorridorOn: deprecatedUserJSONSettings.showCorridorOn });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layout, deprecatedUserJSONSettings]);

    useEffect(() => {
        // Reset current swing state when Kiosk sends us a new activityId
        useGlobalStore.setState({
            activeSwingID: -1,
            swings: [],
        });

        // Update activity context
        if (activityId) {
            showFloorActivity(activityId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activityId]);

    return (
        <Fragment key="floor-screen">
            <ScreenTitle title="Elva Golf — Floor" />
            {shouldInstantReplay && instantReplay.activeId && (
                <VideoReplay
                    videos={instantReplay.videos}
                    onReplayEnded={() => {
                        instantReplay.onReplayEnded();
                    }}
                />
            )}
            <div className={css.root}>
                <TopBar
                    club={selectedClub?.short_name || ''}
                    tags={_.map(tags, (t) => t.name) || []}
                    selectedLayout={activeLayout?.name ?? 'No layout'}
                    progressBar={<> </>}
                />
                {uiNodeTree && activeLayout && (
                    <UIComponentsLayout
                        uiNodeTree={uiNodeTree}
                        layout={activeLayout}
                        currentDevice="floor"
                        values$={values$}
                    />
                )}
            </div>
            {hasEndpoints && <WebsocketInit />}
        </Fragment>
    );
}

function WebsocketInit() {
    useBeastSocketConnection();
    return (
        <>
            <BeastListener />
            <FullAnalysisListener />
        </>
    );
}
