import { forwardRef } from 'react';
import {
    MenuButtonProps,
    MenuItemProps as AriakitMenuItemProps,
    MenuItem as AriakitMenuItem,
    Menu as AriakitMenu,
    MenuProvider,
    MenuButton,
    useMenuStore,
} from '@ariakit/react';
import { CaretCircleDown as CaretDownIcon, X as XIcon } from '@phosphor-icons/react';

import { typography } from '../../typography';
import { colors } from '../../colors';

import * as css from './Menu.css';

export const MenuItem = forwardRef<HTMLDivElement, AriakitMenuItemProps>(function MenuItem(props, ref) {
    return (
        <AriakitMenuItem ref={ref} {...props} className={css.menuItem({ disabled: props.disabled })}>
            {props?.children}
        </AriakitMenuItem>
    );
});

export interface MenuProps extends MenuButtonProps<'div'> {
    label: string;
    align?: 'left' | 'right';
    buttonSize?: number;
    buttonPadding?: number;
    menuType?: 'main' | 'normal';
}

export const Menu = ({
    label,
    children,
    align = 'left',
    buttonSize = 24,
    buttonPadding = 0,
    menuType = 'normal',
    ...props
}: MenuProps) => {
    const menu = useMenuStore();

    const shift = buttonPadding - buttonSize;
    const gutter = -(buttonSize + 24 + buttonPadding);
    const buttonStyle = buttonPadding
        ? {
              padding: buttonPadding,
              margin: -buttonPadding,
              borderBottomLeftRadius: '50%',
          }
        : {};

    return (
        <MenuProvider store={menu}>
            <div>
                <MenuButton {...props} style={buttonStyle}>
                    <CaretDownIcon size={buttonSize} color={colors.blue[500]} weight="fill" />
                </MenuButton>

                <AriakitMenu className={css.menu({ menuType })} portal shift={shift} gutter={gutter}>
                    {!menu.parent && (
                        <div
                            className={css.header({ hasLabel: label?.length > 0 })}
                            style={{ paddingInline: buttonSize }}
                        >
                            {align === 'right' && (
                                <button
                                    onClick={() => {
                                        menu.hide();
                                    }}
                                >
                                    <XIcon size={buttonSize} />
                                </button>
                            )}
                            <p
                                className={
                                    menuType === 'normal'
                                        ? typography({
                                              variant: 'h4',
                                          })
                                        : typography({
                                              variant: 'h3',
                                          })
                                }
                            >
                                {label}
                            </p>
                            {align === 'left' && (
                                <button
                                    onClick={() => {
                                        menu.hide();
                                    }}
                                >
                                    <CaretDownIcon size={buttonSize} color={colors.blue[500]} weight="fill" />
                                </button>
                            )}
                        </div>
                    )}
                    {children}
                </AriakitMenu>
            </div>
        </MenuProvider>
    );
};
