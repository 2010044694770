import { useEffect, useRef } from 'react';
import { resetGlobalState } from 'react-use-websocket';

import { useCommunicationSocket } from '../../state/communicationSocketStore';
import { useRelaySocket } from '../../state/relaySocketStore';
import { useBoothSession } from './useBoothSession';

export function useBeastSocketConnection() {
    const { boothSessionDetails } = useBoothSession();
    const { beastEndpoint, relayEndpoint } = boothSessionDetails!;

    // Use secure sockets by default, but allow overriding with env variable
    const isSecureSockets = import.meta.env['VITE_PUBLIC_UNSECURE_SOCKETS'] !== 'true';
    const socketPrefix = isSecureSockets ? 'wss://' : 'ws://';
    const beastSocketServer = `${socketPrefix}${beastEndpoint}`;
    const relaySocketServer = `${socketPrefix}${relayEndpoint}`;

    const communicationSocket = useRef<unknown | null>(useCommunicationSocket(beastSocketServer));
    const relaySocket = useRef<unknown | null>(useRelaySocket(relaySocketServer));

    useEffect(() => {
        return () => {
            communicationSocket.current = null;
            relaySocket.current = null;
            resetGlobalState(relaySocketServer);
            resetGlobalState(beastSocketServer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [beastEndpoint, relayEndpoint]);
}
