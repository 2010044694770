import { useQuery } from '@tanstack/react-query';
import DataManager from '../DataManager';

export function useActivities() {
    const { isPending: isLoadingActivities, data: activities } = useQuery({
        queryKey: ['activities'],
        queryFn: () => DataManager.getInstance().fetchAllUserActivities(),
        refetchOnMount: true,
    });

    return { isLoadingActivities, activities };
}
