import _ from 'lodash';
import { motion } from 'motion/react';

import { typography } from '../../typography';

import * as css from './Tabs.css';

interface TabsProps {
    items: string[];
    keys?: string[];
    activeItem: string;
    onClick: (activeItem: string) => void;
    getTabLabel?: (tab: string) => string;
}

export function Tabs({ items, keys: _keys, activeItem, onClick, getTabLabel }: TabsProps) {
    const keys = Array.isArray(_keys) ? _keys : items;

    return (
        <div className={css.tabs}>
            {_.map(keys, (key, index) => {
                const isActive = key === activeItem;

                return (
                    <div
                        key={key}
                        className={css.item({ active: isActive })}
                        role="button"
                        onClick={() => onClick(key)}
                    >
                        <h3 className={typography({ variant: 'h3' })}>
                            {getTabLabel ? getTabLabel(key) : items[index]}
                        </h3>
                        {isActive && (
                            <motion.div
                                className={css.underline({ active: true })}
                                layoutId="activeUnderline"
                                transition={{ duration: 0.2, ease: 'circOut' }}
                            />
                        )}
                        <div className={css.underline({ active: false })} />
                    </div>
                );
            })}
        </div>
    );
}
