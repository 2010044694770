// ActivitySidebar is the floor version of ActivityNavigation.
import _ from 'lodash';

import { Swing, localize } from '@common';

import * as css from './ActivitySidebar.css';

function GhostItem({ number, value }: { number: number; value: string }) {
    return (
        <li>
            <span className={css.item({ isAnalyzing: false })}>
                <div className={css.itemNumber}>
                    <span className={css.itemNumberText}>{number}</span>
                </div>
                <span className={css.itemValue}>{value}</span>
            </span>
        </li>
    );
}

type EnhancedSwing = Swing & { value: string; number: number };

type ActivitySidebarProps = {
    selectedKeyParameter: string;
    fallbackDisplayValue: string;
    swings: EnhancedSwing[];
    isAnalyzing: boolean;
};

export function ActivitySidebar({
    selectedKeyParameter,
    fallbackDisplayValue = '—',
    swings = [],
    isAnalyzing = false,
}: ActivitySidebarProps) {
    const latestSwing = swings[0];

    return (
        <div className={css.root}>
            <div className={css.header}>
                <p className={css.headerText}>{localize('activity_navigation.header')}</p>
                <p className={css.parameterName}>{selectedKeyParameter}</p>
            </div>
            <ul className={css.list}>
                {isAnalyzing && <GhostItem number={swings.length + 1} value={fallbackDisplayValue} />}
                {_.map(swings, ({ value, id, number }, index) => {
                    const latestAnalysisID = latestSwing?.id ?? -1;
                    return (
                        <li key={index}>
                            <span className={css.item({ isAnalyzing: id === latestAnalysisID })}>
                                <div className={css.itemNumber}>
                                    <span className={css.itemNumberText}>{number}</span>
                                </div>
                                <span className={css.itemValue}>{value}</span>
                            </span>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}
