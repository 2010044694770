import { createWithEqualityFn } from 'zustand/traditional';
import { subscribeWithSelector } from 'zustand/middleware';

import type { UserSettingsStoreState } from './userSettingsStore.types';
import type { Layout, UINodeTree } from '@core';

/**
 * Notice about User Settings store
 * ------------------------------------------
 *
 * This store was initially used to keep user settings before it was storable in the Layout table.
 * Now it's being used to hold state that should sync with the Floor.
 *
 * A better name would be `Activity Store` when all User Layout related state is moved to the DB.
 *
 */

export const useUserSettingsStore = createWithEqualityFn<UserSettingsStoreState>()(
    subscribeWithSelector((set, get) => ({
        userSettings: {
            language: 'English',
            golfBag: [],
        },

        availableTags: [],

        playbackSettings: {
            playbackAngle: undefined,
            skeletonOverlay: false,
            instantReplay: true,
            playbackSpeed: 1,
        },

        activeCorridor: undefined,

        // Layout
        layouts: [],
        activeLayout: undefined,
        uiNodeTree: undefined,

        actions: {
            setUserSettings: (userSettingsToUpdate) => {
                const merged: UserSettingsStoreState['userSettings'] = {
                    ...get().userSettings,
                    ...userSettingsToUpdate,
                };
                set({ userSettings: merged });
                return merged;
            },
            setAvailableTags: (availableTags) => set({ availableTags }),
            setPlaybackSettings: (playbackSettings) => set({ playbackSettings }),

            // Corridor
            setActiveCorridor: (corridor) => set({ activeCorridor: corridor }),

            // Layout
            setUINodeTree(uiNodes?: UINodeTree) {
                set({ uiNodeTree: uiNodes });
            },
            setLayouts: (layouts) => {
                set({ layouts });
            },
            setActiveLayout: (layout?: Layout) => {
                if (!layout) {
                    return;
                }

                set({
                    activeLayout: layout,
                });
            },
        },
    })),
);
