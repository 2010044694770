import _ from 'lodash';
import {
    Mutable,
    snake_case,
    L10nID,
    Unit,
    MutableProperty,
    UINodeTree,
    UINode,
    UINodeID,
    UIParameter,
    UIGraphLine,
    CategoryID,
    Category,
    CategoryType,
    UINodeRelation,
    AnyUINode,
    UIGraph,
    ALL_AXES,
    isFiniteNumber,
} from '@core';
import { SupabaseService } from './services/supabase.service';

export class UINodesEndpointPlaceholder {
    constructor(private readonly _supabase: SupabaseService) {}

    /**
     * Endpoint for getting all publicly available ui nodes
     */
    async getAllUINodes(type: string[] = [], locales: string[] = ['en-US']): Promise<UINodeTree> {
        // fetch all public ui nodes
        let query = this._supabase
            .from('ui_nodes')
            .select(
                `
                *,
                ui_node_categorizations(ui_node_category_id),
                ui_graphs(*),
                ui_graph_lines(*, time_series_measurements(time_series_types(units(*)))),
                ui_parameters(*, swing_measured_parameters(axis_index_in_time_series, units(*), time_series_measurements(name)))
            `,
            )
            .limit(10_000);
        if (!_.isEmpty(type)) query = query.in('type', type);

        const { data: nodesFromDB, error: nodesError } = await this._supabase.withLimit(10_000, query);

        if (nodesError) {
            console.error('error fetching ui_nodes:', nodesError);
            return {
                nodes: [],
                relations: [],
                categories: [],
            };
        }

        // convert the fetched nodes to the UINode type
        const nodes = _.map(nodesFromDB, (c) => {
            const node: Mutable<MutableProperty<UINode, 'categories'>> = {
                id: c.id as UINodeID,
                type: c.type,
                metadata: this.toObject(c.metadata),
                name: c.name_l10n_id ? { l10n_id: c.name_l10n_id as L10nID, value: '' } : null,
                short_name: c.short_name_l10n_id ? { l10n_id: c.short_name_l10n_id as L10nID, value: '' } : null,
                show_on: c.show_on,
                categories: {},
            };
            if (c.ui_parameters && this.assertNodeType<UIParameter>(node)) {
                const axisIndex = c.ui_parameters?.swing_measured_parameters?.axis_index_in_time_series;
                node.categories.metric = c.ui_parameters.metric as CategoryID<'metric'>;
                node.categories.swing_foundation_group = c.ui_parameters
                    .swing_foundation_group as CategoryID<'swing_foundation_group'>;
                (node as Mutable<UIParameter>).parameter = {
                    id: c.ui_parameters.swing_measured_parameter_name as snake_case,
                    unit: c.ui_parameters?.swing_measured_parameters?.units?.notation as Unit,
                    axis: (isFiniteNumber(axisIndex) && ALL_AXES[axisIndex]) || null,
                    time_series_id: c.ui_parameters?.swing_measured_parameters?.time_series_measurements
                        ?.name as snake_case,
                };
            }
            if (c.ui_graph_lines && this.assertNodeType<UIGraphLine>(node)) {
                node.axis = c.ui_graph_lines.axis;
                node.categories.metric = c.ui_graph_lines.metric as CategoryID<'metric'>;
                node.time_series = {
                    id: c.ui_graph_lines.time_series_measurement_name as snake_case,
                    unit: c.ui_graph_lines?.time_series_measurements?.time_series_types?.units?.notation as Unit,
                };
            }
            if (c.ui_graphs && this.assertNodeType<UIGraph>(node)) {
                node.categories.graph_type = c.ui_graphs.graph_type as CategoryID<'graph_type'>;
            }

            if (!_.isEmpty(c.ui_node_categorizations)) {
                _.merge(
                    node.categories,
                    _.chain({
                        body_parts: 'body_part.',
                        item_parts: 'item_part.',
                    })
                        .mapValues((prefix) =>
                            _(c.ui_node_categorizations)
                                .map((c) => c.ui_node_category_id)
                                .filter((id) => _.startsWith(id, prefix))
                                .value(),
                        )
                        .omitBy(_.isEmpty)
                        .value(),
                );
            }

            return node as AnyUINode;
        });

        const { data: categoriesFromDB, error: categoriesError } = await this._supabase
            .from('ui_node_categories')
            .select('*');

        if (categoriesError) {
            console.error('error fetching ui_node_categories:', categoriesError);
            return {
                nodes: [],
                relations: [],
                categories: [],
            };
        }

        // localize the names
        // get names
        const l10nIDs = _(nodes)
            .flatMap((c) => [c.name?.l10n_id, c.short_name?.l10n_id])
            .concat(_.map(categoriesFromDB, (c) => c.name_l10n_id as L10nID))
            .compact() // remove falsy values
            .uniq() // remove duplicates
            .value();
        const l10nIDLikenesses = _(l10nIDs)
            .map((x) => x.split('.')?.[0] + '.%')
            .uniq()
            .value();
        const { data: namesFromDB, error: namesError } = await this._supabase.withLimit(
            10_000,
            this._supabase
                .from('l10n_strings')
                .select('*')
                .likeAnyOf('l10n_id', l10nIDLikenesses)
                .in('locale_code', locales),
        );
        if (namesError) {
            console.error('error fetching names:', namesError);
            return {
                nodes: [],
                relations: [],
                categories: [],
            };
        }
        // apply names
        const names = _(namesFromDB)
            .groupBy((x) => x.l10n_id)
            .mapValues((x) =>
                _(x)
                    .mapKeys((l) => l.locale_code)
                    .mapValues((l) => l.value)
                    .value(),
            )
            .value();
        for (const n of nodes) {
            const node = n as MutableProperty<UINode, 'name' | 'short_name'>;
            if (node.name?.l10n_id) {
                const name = names[node.name.l10n_id];
                if (name)
                    _.assign(node.name, name, {
                        value: _(locales)
                            .map((l) => name[l])
                            .find(),
                    });
            }
            if (node.short_name?.l10n_id) {
                const name = names[node.short_name.l10n_id];
                if (name)
                    _.assign(node.short_name, name, {
                        value: _(locales)
                            .map((l) => name[l])
                            .find(),
                    });
            }
        }
        const categories = _.map(
            categoriesFromDB,
            (c) =>
                ({
                    id: c.id as CategoryID<CategoryType>,
                    display_order: c.display_order,
                    name: {
                        l10n_id: c.name_l10n_id as L10nID,
                        ...names[c.name_l10n_id],
                        value: _(locales)
                            .map((l) => names[c.name_l10n_id]?.[l])
                            .find(),
                    },
                }) as Category,
        );

        // fetch all public ui_node_relations
        const { data: relationsFromDB, error: relationsError } = await this._supabase.withLimit(
            20_000,
            this._supabase.from('ui_node_relations').select('*'),
        );

        if (relationsError) {
            console.error('error fetching public ui_component_relations:', relationsError);
            return {
                nodes: [],
                relations: [],
                categories: [],
            };
        }

        const relations = _.map(relationsFromDB, (r) => _.omit(r, 'id') as UINodeRelation);

        return {
            nodes,
            relations,
            categories,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private toObject(value: any): object {
        return _.isObject(value) ? value : {};
    }

    private assertNodeType<T extends UINode>(value: UINode): value is MutableProperty<Mutable<T>, 'categories'> {
        return true;
    }
}
