import { ReactNode } from 'react';
import { motion } from 'motion/react';

import * as css from './Card.css';

export default function Card({ children }: { children: ReactNode }) {
    return (
        <motion.div
            transition={{
                y: { type: 'spring', bounce: 0 },
                opacity: { duration: 0.1 },
            }}
            initial={{ opacity: 0, y: '10px' }}
            animate={{ opacity: 1, y: '0px' }}
            exit={{ opacity: 0, y: '0px' }}
            className={css.container}
        >
            {children}
        </motion.div>
    );
}
