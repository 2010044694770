import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { PencilCircle as PencilCircleIcon, CopySimple as CopyIcon } from '@phosphor-icons/react';
import { Dialog, DialogDismiss, DialogHeading } from '@ariakit/react';
import { AnimatePresence, LayoutGroup, motion } from 'motion/react';

import { DEFAULT_LAYOUT_NAME, Layout, localize } from '@core';
import { Button, SelectBox } from '@ui';
import { CircularClose } from '../../../components/icons/CircularClose';
import Input from '../../../components/Input';
import { UserSettingsContext } from '../../../utils/contexts';
import { useUserSettingsStore } from '../../../state/userSettingsStore';
import { TrashIcon } from '../../../components/icons/TrashIcon';

import * as css from './LayoutListEditorModal.css';

export function LayoutListEditorModal({
    isOpen,
    setIsOpen,
    layoutBeingEdited,
}: {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    layoutBeingEdited: Layout | null;
}) {
    const { getLayouts, setActiveLayout, createUserLayout, updateUserLayout, deleteUserLayout } =
        useContext(UserSettingsContext);
    const [activeLayout, layouts] = useUserSettingsStore((state) => [state.activeLayout, state.layouts]);

    const [canAnimate, setCanAnimate] = useState(false);

    useEffect(
        () => {
            // fetch all layouts every time the modal is opened
            if (isOpen) {
                displayLoadingIndicatorWhile(getLayouts);
                setTimeout(() => setCanAnimate(true), 100);
            } else {
                setLayoutBeingCreated(null);
                setLayoutBeingRenamed(null);
                setLayoutBeingDeleted(null);
                setIsLoading(false);
                setCanAnimate(false);
            }
        },
        [isOpen], // eslint-disable-line react-hooks/exhaustive-deps
    );

    const [layoutBeingCreated, setLayoutBeingCreated] = useState(null as Layout | null);
    const [layoutBeingRenamed, setLayoutBeingRenamed] = useState(null as Layout | null);
    const [layoutBeingDeleted, setLayoutBeingDeleted] = useState(null as Layout | null);
    const [layoutBeingActivated, setLayoutBeingActivated] = useState(null as Layout | null); // used to confirm if you want to discard changes on current active layout
    const [isLoading, setIsLoading] = useState(false);
    const displayLoadingIndicatorWhile = (asyncFunction: () => Promise<unknown>) => {
        const delayedIndicator = setTimeout(() => setIsLoading(true), 100);
        asyncFunction().finally(() => {
            clearTimeout(delayedIndicator);
            setIsLoading(false);
        });
    };

    const isEditing = layoutBeingCreated || layoutBeingRenamed || layoutBeingDeleted;

    return (
        isOpen && (
            <AnimatePresence>
                <Dialog
                    key="layout_list_editor_dialog"
                    className={css.root}
                    open={isOpen}
                    onClose={() => {
                        setIsOpen(false);
                    }}
                    backdrop={
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            style={{
                                backgroundColor: 'rgb(0,0,0,0.3)',
                            }}
                        />
                    }
                >
                    {/* Dialog content */}
                    <div className={css.content}>
                        <DialogHeading className={css.modalHeading}>
                            <span>{localize('layout_list_editor.title')}</span>
                            <DialogDismiss>
                                <CircularClose width={24} />
                            </DialogDismiss>
                        </DialogHeading>

                        <div className={css.layoutList}>
                            <LayoutGroup>
                                {_(layouts || [])
                                    .sortBy((l) => l.name.toLocaleLowerCase())
                                    .concat(layoutBeingCreated ? [layoutBeingCreated] : [])
                                    .thru(
                                        (list) =>
                                            layoutBeingCreated?.owner_user_id
                                                ? _.sortBy(
                                                      list,
                                                      (l) => l.name.toLocaleLowerCase(),
                                                      (l) => l.id,
                                                  ) // we're cloning
                                                : list, // we're creating a new empty layout
                                    )
                                    .map((layout) => (
                                        <motion.div
                                            layout="position"
                                            key={layout.id}
                                            transition={{
                                                duration: canAnimate ? 0.35 : 0,
                                                ease: 'easeInOut',
                                            }}
                                            initial={false}
                                        >
                                            <LayoutListItem
                                                layout={layout}
                                                layoutBeingCreated={layoutBeingCreated}
                                                layoutBeingRenamed={layoutBeingRenamed}
                                                isActive={activeLayout?.id === layout.id}
                                                canDelete={!!layouts && layouts.length > 1}
                                                onSelect={() => {
                                                    if (isEditing) return;

                                                    // check if we have unsaved changes to the active layout
                                                    if (!_.isEqual(activeLayout, layoutBeingEdited)) {
                                                        setLayoutBeingActivated(layout);
                                                        return;
                                                    }

                                                    // if no changes, just set the active layout
                                                    setActiveLayout(layout);
                                                    setIsOpen(false);
                                                }}
                                                onRename={() => {
                                                    if (isEditing) return;
                                                    setLayoutBeingRenamed(layout);
                                                }}
                                                onClone={() => {
                                                    if (isEditing) return;
                                                    const clonedLayout = { ...layout };
                                                    clonedLayout.id = -1;
                                                    clonedLayout.name = `${layout.name} (copy)`;
                                                    setLayoutBeingCreated(clonedLayout);
                                                }}
                                                onDelete={() => {
                                                    if (isEditing) return;
                                                    setLayoutBeingDeleted(layout);
                                                }}
                                                onCancel={() => {
                                                    setLayoutBeingCreated(null);
                                                    setLayoutBeingRenamed(null);
                                                    setLayoutBeingDeleted(null);
                                                }}
                                                onSave={(name: string) =>
                                                    displayLoadingIndicatorWhile(async () => {
                                                        const renamedLayout = { ...layout, name };
                                                        if (layout.id > 0) await updateUserLayout(renamedLayout);
                                                        else await createUserLayout(renamedLayout);
                                                        setLayoutBeingCreated(null);
                                                        setLayoutBeingRenamed(null);
                                                        setLayoutBeingDeleted(null);
                                                    })
                                                }
                                            />
                                        </motion.div>
                                    ))
                                    .value()}
                                {!layoutBeingCreated && (
                                    <div className={css.createButtonContainer}>
                                        <Button
                                            variant="secondary"
                                            size="small"
                                            icon="plus"
                                            iconColor={css.createButtonIconColor}
                                            onClick={() => {
                                                const newLayout: Layout = {
                                                    id: -1,
                                                    owner_user_id: '',
                                                    name: '',
                                                    customizations: [],
                                                };
                                                setLayoutBeingCreated(newLayout);
                                            }}
                                        >
                                            {localize('layout_list_editor.create_button')}
                                        </Button>
                                    </div>
                                )}
                            </LayoutGroup>
                        </div>
                    </div>

                    {/* Loading indicator */}
                    {isLoading && (
                        <div className={css.loadingIndicatorBackdrop}>
                            <div className={css.spinner} />
                        </div>
                    )}

                    {/* Dialog to confirm if you want to delete a layout */}
                    <AnimatePresence>
                        {layoutBeingDeleted && (
                            <Dialog
                                key="delete_dialog"
                                open={true}
                                className={css.deleteDialog}
                                backdrop={
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        style={{
                                            backgroundColor: 'rgb(0,0,0,0.3)',
                                            zIndex: 99,
                                        }}
                                    />
                                }
                                render={
                                    <motion.div
                                        initial={{ opacity: 0, scale: 0.95 }}
                                        animate={{ opacity: 1, scale: 1 }}
                                        exit={{ opacity: 0, scale: 0.95 }}
                                    />
                                }
                            >
                                <div className={css.deleteDialogContent}>
                                    {localize('layout_list_editor.delete_are_you_sure').replace(
                                        '{layoutName}',
                                        layoutBeingDeleted?.name || '',
                                    )}
                                </div>
                                <div className={css.deleteDialogButtons}>
                                    <Button
                                        key="cancel_delete"
                                        variant="secondary"
                                        size="medium"
                                        onClick={() => {
                                            setLayoutBeingDeleted(null);
                                        }}
                                    >
                                        {localize('layout_list_editor.delete_cancel_button')}
                                    </Button>
                                    <Button
                                        key="confirm_delete"
                                        variant="primary"
                                        size="medium"
                                        onClick={() => {
                                            if (!layoutBeingDeleted) return;
                                            displayLoadingIndicatorWhile(() => deleteUserLayout(layoutBeingDeleted));
                                            setLayoutBeingDeleted(null);
                                        }}
                                    >
                                        {localize('layout_list_editor.delete_confirm_button')}
                                    </Button>
                                </div>
                            </Dialog>
                        )}
                    </AnimatePresence>

                    {/* Dialog to confirm if you want to save or discard changes on the active layout */}
                    <AnimatePresence>
                        {layoutBeingActivated && (
                            <Dialog
                                key="save_or_discard_dialog"
                                open={true}
                                className={css.deleteDialog}
                                backdrop={
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        style={{
                                            backgroundColor: 'rgb(0,0,0,0.3)',
                                            zIndex: 99,
                                        }}
                                    />
                                }
                                render={
                                    <motion.div
                                        initial={{ opacity: 0, scale: 0.95 }}
                                        animate={{ opacity: 1, scale: 1 }}
                                        exit={{ opacity: 0, scale: 0.95 }}
                                    />
                                }
                            >
                                <div className={css.deleteDialogContent}>
                                    {localize('layout_list_editor.save_active_changes_are_you_sure').replace(
                                        '{layoutName}',
                                        activeLayout?.name || '',
                                    )}
                                </div>
                                <div className={css.deleteDialogButtons}>
                                    <Button
                                        key="confirm_discard"
                                        variant="secondary"
                                        size="medium"
                                        onClick={() => {
                                            setActiveLayout(layoutBeingActivated);
                                            setLayoutBeingActivated(null);
                                            setIsOpen(false);
                                        }}
                                    >
                                        {localize('layout_list_editor.cancel_name_change_button')}
                                    </Button>
                                    <Button
                                        key="confirm_save"
                                        variant="primary"
                                        size="medium"
                                        onClick={() => {
                                            displayLoadingIndicatorWhile(async () => {
                                                if (layoutBeingEdited) await updateUserLayout(layoutBeingEdited);
                                                setActiveLayout(layoutBeingActivated);
                                                setLayoutBeingActivated(null);
                                                setIsOpen(false);
                                            });
                                        }}
                                    >
                                        {localize('layout_editor.save_button')}
                                    </Button>
                                </div>
                            </Dialog>
                        )}
                    </AnimatePresence>
                </Dialog>
            </AnimatePresence>
        )
    );
}

export function LayoutListItem({
    layout,
    layoutBeingCreated,
    layoutBeingRenamed,
    isActive, // TODO: Implement this
    canDelete,
    onSelect,
    onRename,
    onClone,
    onDelete,
    onCancel,
    onSave,
}: {
    layout: Layout;
    layoutBeingCreated: Layout | null;
    layoutBeingRenamed: Layout | null;
    isActive: boolean;
    canDelete: boolean;
    onSelect: () => void;
    onRename: () => void;
    onClone: () => void;
    onDelete: () => void;
    onCancel: () => void;
    onSave: (name: string) => void;
}) {
    const [name, setName] = useState(layout.name);

    const isCreating = layoutBeingCreated?.id === layout?.id;
    const isRenaming = layoutBeingRenamed?.id === layout?.id;

    // disabled if another layout is being created or renamed
    const isDisabled = (layoutBeingCreated || layoutBeingRenamed || layout).id !== layout.id;

    return isCreating || isRenaming ? (
        <div className={css.layoutListItem}>
            <Input
                className={css.layoutNameInput}
                type="text"
                value={name}
                onChange={(value) => setName(value.target.value)}
                placeholder={localize('layout_list_editor.layout_name_placeholder')}
                onSubmit={() => onSave(name || DEFAULT_LAYOUT_NAME)}
            />
            <div className={css.layoutActionButtons}>
                <button key="cancel" className={css.cancelButton} onClick={() => onCancel()}>
                    {localize(
                        isCreating
                            ? 'layout_list_editor.cancel_create_button'
                            : 'layout_list_editor.cancel_name_change_button',
                    )}
                </button>
                <button key="confirm" className={css.confirmButton} onClick={() => onSave(name || DEFAULT_LAYOUT_NAME)}>
                    {localize(
                        isCreating
                            ? 'layout_list_editor.confirm_create_button'
                            : 'layout_list_editor.confirm_name_change_button',
                    )}
                </button>
            </div>
        </div>
    ) : (
        <div className={css.layoutListItem}>
            <button
                key="select"
                className={css.layoutSelectButton}
                onClick={() => isDisabled || onSelect()}
                disabled={isDisabled}
            >
                <SelectBox selected={isActive} />
                <span>{layout.name}</span>
            </button>
            <div className={css.layoutActionButtons}>
                <button
                    key="rename"
                    onClick={() => {
                        if (isDisabled) return;
                        setName(layout.name);
                        onRename();
                    }}
                    className={css.renameButton}
                    disabled={isDisabled}
                >
                    <PencilCircleIcon color="black" size={24} />
                </button>
                <button
                    key="clone"
                    onClick={() => isDisabled || onClone()}
                    className={css.cloneButton}
                    disabled={isDisabled}
                >
                    <CopyIcon color="black" size={24} />
                </button>
                <button
                    key="delete"
                    onClick={() => isDisabled || onDelete()}
                    className={css.deleteButton}
                    disabled={isDisabled || !canDelete}
                >
                    <TrashIcon color="black" width={24} height={24} />
                </button>
            </div>
        </div>
    );
}
