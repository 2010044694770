import _ from 'lodash';
import { useActivityContext } from '../utils/contexts/ActivityContext';
import { Analysis, ANALYSIS_TYPES, CommunicationMessage } from '@common';
import { useEffect, useState } from 'react';
import { BeastCommunicationState, useCommunicationSocketStore } from '../state/communicationSocketStore';
import {
    AnalysisFailedMessage,
    AnalysisMessage,
    AnalysisResponse,
    AnalysisStateMessage,
    BeastStatusMessage,
    VideoResponse,
    VideosMessage,
    AnalysisCache,
} from './BeastListener.types';
import { triggerInstantReplayByID, useGlobalStore } from '../state/globalStore';
import { SocketPayload } from '../state/relaySocketStore';
import { transformSkeletonToFramesFirst } from '../utils/skeletonUtils';
import { ImagesMessage, ImagesResponse } from '../DataManager/DataManager.types';

const partialAnalysesCache: Record<string, AnalysisCache> = {};
const CAMERA_NAMES = ['back', 'down_the_line', 'face_on', 'trail_front'];

async function processPartialSwing(
    id: number,
    type: 'VIDEOS' | 'ANALYSIS' | 'IMAGES',
    partialSwing: VideoResponse | AnalysisResponse | ImagesResponse,
) {
    // Initialize a partial analysis if none exists.
    if (!partialAnalysesCache[id]) {
        partialAnalysesCache[id] = {
            videoData: null,
            analysisData: null,
            imageData: null,
        };
    }

    switch (type) {
        case 'VIDEOS': {
            const { total_frames, urls, dimensions } = partialSwing as VideoResponse;

            const videos = _.map(CAMERA_NAMES, (name) => ({
                name,
                url: urls[name],
                metadata: {
                    totalFrames: total_frames,
                    sourceWidth: dimensions?.sourceWidth ?? 2464,
                    sourceHeight: dimensions?.sourceHeight ?? 2064,
                },
            }));

            partialAnalysesCache[id].videoData = {
                id,
                data: {
                    videos,
                    analysis: partialAnalysesCache[id].analysisData?.data.analysis || null,
                },
            };

            triggerInstantReplayByID(id, videos);

            break;
        }
        case 'ANALYSIS': {
            const { analysis_url } = partialSwing as AnalysisResponse;

            const data = await fetch(analysis_url);
            const json = await data.json();

            const timestamp = Date.now();
            const frames = transformSkeletonToFramesFirst(json.skeleton);

            partialAnalysesCache[id].analysisData = {
                id,
                data: {
                    analysis: {
                        timestamp,
                        isQuickAnalysis: frames.length <= 10,
                        frames,
                        bones: json.bones,
                        measurements: json.measurements,
                        parameter_values: json.parameter_values,
                        segmentation: json.segmentation,
                        skeleton: json.skeleton,
                    },
                    videos: partialAnalysesCache[id].videoData?.data.videos || null,
                },
            };

            break;
        }
        case 'IMAGES': {
            const { urls } = partialSwing as AnalysisResponse;
            partialAnalysesCache[id].imageData = {
                id,
                data: urls,
            };
            break;
        }
    }

    const { videoData, analysisData, imageData } = partialAnalysesCache[id];

    // Once we have both analysis and videos, save to store
    if (videoData && analysisData && imageData) {
        // A complete swing
        const analysis: Analysis = {
            id,
            data: {
                videos: videoData.data.videos,
                analysis: analysisData.data.analysis,
                images: imageData.data,
            },
        };
        delete partialAnalysesCache[id];

        return analysis;
    }
    return null;
}

function reflectBeastStatus(status: string) {
    if (
        // Statuses we want reflected
        status === 'WAITING_FOR_BALL' ||
        status === 'ANALYZING_SWING' ||
        status === 'BALL_DETECTED' ||
        status === 'BALL_STEADY' ||
        status === 'SWING_ANALYSIS_FAILED' ||
        status === 'IDLE' ||
        status === 'FINALIZING'
    ) {
        useGlobalStore.setState({
            lastBeastStatus: status,
        });
    }
}

function sendActivityIdToBeast(state: BeastCommunicationState, activityId: number) {
    const messages = [
        {
            type: CommunicationMessage.CURRENT_ACTIVITY_ID,
            content: { activity_id: activityId },
        },
        {
            type: CommunicationMessage.INSTRUCTION,
            content: { action: 'ACTIVATE' },
        },
    ];

    for (const message of messages) {
        state.actions.sendCommunicationMessage(message as unknown as SocketPayload);
    }
}

export function BeastListener() {
    const [isConnected, setIsConnected] = useState(false);
    const { currentActivityId, addAnalysisToSwing } = useActivityContext();

    useEffect(() => {
        const handleBeastPayload = async (payload: any): Promise<void> => {
            const { type, content } = payload;

            const handlers: Record<string, (content: any) => Promise<void>> = {
                BEAST_STATUS: async (content: BeastStatusMessage) => {
                    useGlobalStore.setState({
                        isAnalyzing: content.status === 'ANALYZING_SWING',
                    });
                    reflectBeastStatus(content.status);
                },
                SWING_VIDEOS_AVAILABLE: async (content: VideosMessage) => {
                    const { swing_id, ...rest } = content;
                    const swing = await processPartialSwing(swing_id, 'VIDEOS', rest);
                    if (swing) {
                        addAnalysisToSwing(swing.id, ANALYSIS_TYPES['QUICK_ANALYSIS'], swing);
                        useGlobalStore.setState({
                            isAnalyzing: false,
                        });
                    }
                },
                ANALYSIS_AVAILABLE: async (content: AnalysisMessage) => {
                    const { swing_id, ...rest } = content;
                    const swing = await processPartialSwing(swing_id, 'ANALYSIS', rest);
                    if (swing) {
                        addAnalysisToSwing(swing.id, ANALYSIS_TYPES['QUICK_ANALYSIS'], swing);
                        useGlobalStore.setState({
                            isAnalyzing: false,
                        });
                    }
                },
                POSITION_IMAGES_AVAILABLE: async (content: ImagesMessage) => {
                    const { swing_id } = content;
                    const swing = await processPartialSwing(swing_id, 'IMAGES', content);
                    if (swing) {
                        addAnalysisToSwing(swing.id, ANALYSIS_TYPES['QUICK_ANALYSIS'], swing);
                        useGlobalStore.setState({
                            isAnalyzing: false,
                        });
                    }
                },
                ANALYSIS_STATE: async (content: AnalysisStateMessage) => {
                    console.log('TODO: Handle analysis state', content.state);
                },
                SWING_ANALYSIS_FAILED: async (content: AnalysisFailedMessage) => {
                    reflectBeastStatus('SWING_ANALYSIS_FAILED');
                    if (content.swing_id === null) return;
                },
            };

            const handler = handlers[type];
            if (handler) {
                await handler(content);
            }
        };

        const unsubscribe = useCommunicationSocketStore.subscribe(async (state: BeastCommunicationState) => {
            if (state.isConnected && !isConnected && currentActivityId > 0) {
                sendActivityIdToBeast(state, currentActivityId);
            }

            setIsConnected(state.isConnected);

            if (state.payload) {
                await handleBeastPayload(state.payload);
            }
        });

        return () => {
            unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentActivityId]);

    useEffect(() => {
        if (currentActivityId > 0) {
            sendActivityIdToBeast(useCommunicationSocketStore.getState(), currentActivityId);
        }
    }, [currentActivityId]);

    return null;
}
