import type { SwingPosition } from './analysis.types';

// Limit the use of zIndex to these.
const ZINDEX = {
    ABOVE1: 1,
    ABOVE2: 2,
    ABOVE3: 3,
    ABOVE4: 4,
    MODAL: 5,
    UNDER1: -1,
    UNDER2: -2,
};

// High speed cameras
// TODO: It's very important that this number is correct so that we know how to play 1x speed videos.
// Seems like this needs to be part of the camera config.
const CAMERA_FPS = 255;
// Playback fps of video files
const VIDEO_FPS = 25;

// 1, 2, 4, and 7 are KEY positions
export const KEY_POSITION_INDICES = [0, 1, 3, 6];

export const SWING_POSITIONS: SwingPosition[] = ['p1', 'p2', 'p3', 'p4', 'p5', 'p6', 'p7', 'p8', 'p9', 'p10'];

const SCREEN = {
    SPACING: 20,
    HEADER_HEIGHT: 84,
};

export const constants = {
    ZINDEX,
    CAMERA_FPS,
    VIDEO_FPS,
    KEY_POSITION_INDICES,
    SWING_POSITIONS,
    SCREEN,
};
