// Added for Jan 2025 trade show. Should be removed after.
export const UNWANTED_SWING_FOUNDATIONS_PARAMS = [
    'pelvis_rotation_p2',
    'pelvis_rotation_p4',
    'pelvis_rotation_p7',
    'pelvis_tilt_p1',
    'pelvis_tilt_p2',
    'pelvis_tilt_p4',
    'pelvis_tilt_p7',
    'head_rotation_p1',
    'head_rotation_p2',
    'head_rotation_p4',
    'head_rotation_p7',
    'head_tilt_p1',
    'head_tilt_p2',
    'head_tilt_p4',
    'head_tilt_p7',
    'head_bend_p1',
    'head_bend_p2',
    'head_bend_p4',
    'head_bend_p7',
];
