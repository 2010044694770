export const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('en-GB');
};

export const formatDateFromEpoch = (epoch: number | string) => {
    const date = new Date(Number(epoch));
    return date.toLocaleDateString('en-GB'); // 'en-GB' gives "DD/MM/YYYY" format
};

export const formatTime = (date: string) => {
    return new Date(date).toLocaleTimeString('en-GB');
};
