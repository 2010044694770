import { AnimatePresence, motion } from 'motion/react';
import { Dialog, DialogDismiss, DialogHeading } from '@ariakit/react';

import { Club } from '@common';
import { useGlobalStore } from '../../../state/globalStore';
import { CircularClose } from '../../icons/CircularClose';
import { typography } from '../../../styles/typography.css';
import { ClubTable } from '../../ClubTable';

import * as css from './ClubTableModal.css';

export function ClubTableModal({
    modalOpen,
    setModalOpen,
    onClubClick,
    selectedClub,
}: {
    modalOpen: boolean;
    setModalOpen: (open: boolean) => void;
    onClubClick: (club: Club) => void;
    selectedClub?: Club;
}) {
    const clubs = useGlobalStore((state) => state.clubs);

    return (
        <AnimatePresence>
            {modalOpen && (
                <Dialog
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    className={css.modal}
                    backdrop={
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            style={{
                                backgroundColor: 'rgb(0,0,0,0.3)',
                            }}
                        />
                    }
                    render={
                        <motion.div
                            initial={{ opacity: 0, scale: 0.95 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.95 }}
                        />
                    }
                >
                    <div className={css.modalHeader}>
                        <DialogHeading
                            className={typography({
                                size: 'h3',
                                weight: 'bold',
                                color: 'dark',
                            })}
                        >
                            Filter by club
                        </DialogHeading>
                        <DialogDismiss>
                            <CircularClose width={24} />
                        </DialogDismiss>
                    </div>

                    <ClubTable
                        clubs={clubs}
                        onClubClick={onClubClick}
                        selectedClubs={selectedClub ? [selectedClub] : []}
                    />
                </Dialog>
            )}
        </AnimatePresence>
    );
}
