import { ReactNode, forwardRef } from 'react';

import { localize } from '@core';
import { typography } from '@ui';

import * as css from './ModuleBase.floor.css';

const Fallback = ({ title = 'No data', subtitle = '' }) => (
    <div className={css.root}>
        <div className={css.top}>
            <h3 className={css.title}>{title}</h3>
            {subtitle && <p className={css.subtitle}>{subtitle}</p>}
        </div>
        <div className={css.body}>
            <div className={css.fallback}>
                <span
                    className={typography({
                        variant: 'h2',
                    })}
                >
                    {localize('error_message.no_data_yet')}
                </span>
            </div>
        </div>
    </div>
);

type ModuleBaseProps = {
    title?: string;
    subtitle?: string;
    children: ReactNode;
    showFallback?: boolean;
};

const ModuleBase = forwardRef<HTMLDivElement, ModuleBaseProps>(
    ({ title, subtitle, children, showFallback = false }, ref) => {
        if (showFallback) {
            return <Fallback title={title} subtitle={subtitle} />;
        }

        return (
            <div className={css.root} ref={ref}>
                <div className={css.top}>
                    <h3 className={css.title}>{title ?? 'Module'}</h3>
                    {subtitle && <p className={css.subtitle}>{subtitle}</p>}
                </div>
                <div className={css.body}>{children}</div>
            </div>
        );
    },
);

ModuleBase.displayName = 'ModuleBase';

export default ModuleBase;
