import { useNavigate } from 'react-router-dom';
import {
    ArrowCounterClockwise as ArrowCounterClockwiseIcon,
    ClockClockwise as ClockClockwiseIcon,
} from '@phosphor-icons/react';

import * as css from './OldActivityTopBar.css';
import { useActivityContext } from '../../utils/contexts/ActivityContext';

const formatDate = (date: string | undefined) => {
    if (!date) return 'N/A';
    return date.split('T')[0].replace(/-/g, '/');
};

const formatTime = (date: string | undefined) => {
    if (!date) return 'N/A';
    const timeSplit = date.split('T')[1]?.split(':');
    return timeSplit ? `${timeSplit[0]}:${timeSplit[1]}` : 'N/A';
};

const OldActivityTopBar = () => {
    const { activity } = useActivityContext();
    const navigate = useNavigate();

    const navigateBack = () => {
        navigate('/kiosk/current');
    };

    return (
        <div className={css.bar}>
            <div className={css.content}>
                <div>
                    <button onClick={navigateBack} className={css.group}>
                        <ArrowCounterClockwiseIcon />
                        <span>Back</span>
                    </button>
                </div>
                <span>{formatDate(activity?.start_time)}</span>
                <div className={css.group}>
                    <ClockClockwiseIcon />
                    <span>{formatTime(activity?.start_time)}</span>
                </div>
            </div>
        </div>
    );
};

export default OldActivityTopBar;
