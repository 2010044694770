import _ from 'lodash';
import { useContext, useState } from 'react';
import { AnimatePresence, motion } from 'motion/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import cn from 'classnames';

import type { Club } from '@core';
import { Button } from '@ui';
import { useUserSettingsStore } from '../../state/userSettingsStore';
import { typography } from '../../styles/typography.css';
import { BACK_NAV_HEIGHT } from '../../utils/consts';
import { ClubTable } from '../../components/ClubTable';
import { useGlobalStore } from '../../state/globalStore';
import { ActivityContext } from '../../utils/contexts/ActivityContext';

import * as css from './ClubSelection.css';

export default function ClubSelection() {
    const navigate = useNavigate();
    const clubs = useGlobalStore((state) => state.clubs);
    const activityContext = useContext(ActivityContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const { userSettings, activityClub } = useUserSettingsStore((state) => state);
    const [selectedClub, setSelectedClub] = useState<Club>(activityClub || clubs[0]?.clubs?.[0]);

    const displayMode = searchParams.get('display') ?? ('all' as 'all' | 'bag');

    const bagClubs = _.map(clubs, (clubType) => {
        return {
            type_name: clubType.type_name,
            clubs: _.filter(clubType.clubs, (club) => _.find(userSettings.golfBag, (c) => c.id === club.id)) as Club[],
        };
    });

    const onSave = () => {
        activityContext?.selectClub(selectedClub);
        navigate('/kiosk/current');
    };

    return (
        <div className={css.root}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: BACK_NAV_HEIGHT,
                }}
            >
                <p
                    className={typography({
                        size: 'h2',
                        weight: 'bold',
                        color: 'dark',
                        lineHeight: '1.2',
                    })}
                >
                    Clubs
                </p>
                <div className={css.toggle}>
                    <button
                        className={css.toggleItem}
                        onClick={() => {
                            setSearchParams({ display: 'all' });
                        }}
                        key="all"
                    >
                        <p
                            className={cn([
                                typography({
                                    size: 'h3',
                                    weight: 'bold',
                                    color: displayMode === 'all' ? 'blue' : 'dark',
                                }),
                                css.toggleText,
                            ])}
                        >
                            All clubs
                        </p>
                        {displayMode === 'all' ? (
                            <motion.div className={css.toggleIndicator} layoutId="active" />
                        ) : null}
                    </button>
                    <button
                        className={css.toggleItem}
                        onClick={() => {
                            setSearchParams({ display: 'bag' });
                        }}
                        key="bag"
                    >
                        <p
                            className={cn([
                                typography({
                                    size: 'h3',
                                    weight: 'bold',
                                    color: displayMode === 'bag' ? 'blue' : 'dark',
                                }),
                                css.toggleText,
                            ])}
                        >
                            My golf bag
                        </p>
                        {displayMode === 'bag' ? (
                            <motion.div className={css.toggleIndicator} layoutId="active" />
                        ) : null}
                    </button>
                </div>
                <div className={css.buttons}>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            navigate('/kiosk/current');
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            onSave();
                        }}
                    >
                        Save
                    </Button>
                </div>
            </div>
            <div className={css.contentWrapper}>
                <AnimatePresence mode="wait">
                    {displayMode === 'bag' ? (
                        <ClubTable
                            key="bag"
                            clubs={bagClubs}
                            selectedClubs={[selectedClub]}
                            onClubClick={setSelectedClub}
                        />
                    ) : (
                        <ClubTable
                            key="all"
                            clubs={clubs}
                            selectedClubs={[selectedClub]}
                            onClubClick={setSelectedClub}
                        />
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
}
