import type { UIGraphLine, StaticL10nID, Analysis } from '@core';
import { localize } from '@core';
import { Graph } from '@ui';
import type { ImplementationOf, UIComponentProps } from '../../UIComponent.types';
import ModuleBaseKiosk from '../../../ModuleBase/ModuleBase.kiosk';
import ModuleBaseFloor from '../../../ModuleBase/ModuleBase.floor';
import { getSegmentation, graphDataFromLines, parseAndGroupLines } from './Graph.utils';
import { useGlobalStore, useSelectedAnalysis } from '../../../../state/globalStore';

export const GraphImplementation: ImplementationOf<'graph'> = ({ node, children: _children, currentDevice }) => {
    const children = _children as UIComponentProps<UIGraphLine>[];
    const [currentFrame, activePosition] = useGlobalStore((state) => [state.currentFrame, state.activePosition]);

    // TODO: Should we toggle `transition` and `apex` on/off via metadata or node.id?
    const includeTransitionPoints = node.id === 'graph.transition_sequence';
    const includeApexPoints = node.id === 'graph.timing_sequence';

    const lines = parseAndGroupLines(children);
    const shouldUseLatestSwing = currentDevice === 'floor';
    const selectedAnalysis: Analysis | null = useSelectedAnalysis(shouldUseLatestSwing) || null;
    const segmentation = selectedAnalysis && getSegmentation(selectedAnalysis);
    const graphData = graphDataFromLines(lines, selectedAnalysis, segmentation, {
        includeTransitionPoints,
        includeApexPoints,
    });
    const isPending = !graphData;

    switch (currentDevice) {
        case 'kiosk': {
            return (
                <ModuleBaseKiosk
                    title={node.name?.value}
                    subtitle={localize(`${node.categories.graph_type}` as StaticL10nID, 'graph_type.fallback')}
                    isPending={isPending}
                >
                    {!isPending && (
                        <Graph
                            data={graphData}
                            lines={lines}
                            segmentation={segmentation!}
                            device="kiosk"
                            currentFrame={currentFrame}
                            activePosition={activePosition}
                        />
                    )}
                </ModuleBaseKiosk>
            );
        }
        case 'floor': {
            return (
                <ModuleBaseFloor
                    title={node.name?.value}
                    subtitle={localize(`${node.categories.graph_type}` as StaticL10nID, 'graph_type.fallback')}
                    showFallback={isPending}
                >
                    {!isPending && <Graph data={graphData} lines={lines} segmentation={segmentation!} device="floor" />}
                </ModuleBaseFloor>
            );
        }
    }
};
