import { PostgrestResponse, SupabaseClient } from '@supabase/supabase-js';
import { PostgrestQueryBuilder, PostgrestFilterBuilder } from '@supabase/postgrest-js';
import { Database } from '@core';
import _ from 'lodash';

export type Tables = Database['public']['Tables'];
export type TableNames = keyof Tables;

export type Table<TTableName extends TableNames> = Tables[TTableName]['Row'];
export type TableInsert<TTableName extends TableNames> = Tables[TTableName]['Insert'];
export type TableUpdate<TTableName extends TableNames> = Tables[TTableName]['Update'];

export class SupabaseService {
    constructor(private readonly _supabase: SupabaseClient<Database>) {}

    /**
     * Perform a query on a table or a view.
     *
     * @param relation - The table or view name to query
     */
    public from<TTableName extends TableNames>(
        relation: TTableName,
    ): PostgrestQueryBuilder<Database['public'], Tables[TTableName], TTableName> {
        return this._supabase.from<TTableName, Tables[TTableName]>(relation);
    }
    public get auth() {
        return this._supabase.auth;
    }

    public async withLimit<TReturn>(
        limit: number,
        query: PostgrestFilterBuilder<never, never, TReturn[]>,
    ): Promise<PostgrestResponse<TReturn>> {
        if (!_.isFinite(limit)) return await query;
        if (limit <= 1000) return await query.limit(limit);

        // if limit is greater than 1000, we need to paginate
        const allResults: TReturn[] = [];
        let result: PostgrestResponse<TReturn> = { data: [], error: null, count: 0, status: 200, statusText: 'OK' };
        let offset = 0;
        while (offset < limit) {
            result = await query.range(offset, offset + 999);
            if (result.data && !_.isEmpty(result.data)) allResults.push(...result.data);
            if (result.error || result.data.length < 1000) break;

            offset += 1000;
        }
        result.count = allResults.length;
        result.data = allResults;
        return result;
    }
}
