import _ from 'lodash';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Gear as GearIcon,
    ClockCounterClockwise as ClockIcon,
    XCircle as XCircleIcon,
    SignOut as SignOutIcon,
} from '@phosphor-icons/react';
import axios from 'axios';

import { localize } from '@core';
import { Button, Menu, MenuItem, PropertyTrigger, colors, typography } from '@ui';
import { useGlobalStore } from '../../state/globalStore';
import { useUserSettingsStore } from '../../state/userSettingsStore';
import ElvaLogoName from '../Logo/ElvaLogoName';
import { useSession } from '../../utils/hooks/useSession';
import { useBoothSession } from '../../utils/hooks/useBoothSession';

import * as css from './Navigation.css';

const DevTools = () => {
    const [isSpamming, setIsSpamming] = useState(false);

    //TODO(at): Make production not use DEV mode
    if (window.location.hostname !== 'localhost') {
        return null;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
            <Button
                onClick={async () => {
                    try {
                        await axios.post(`http://localhost:8080/${isSpamming ? 'stop-spam' : 'start-spam'}`, {});
                        setIsSpamming(!isSpamming);
                    } catch (error) {
                        console.error('Error calling beast simulator', error);
                    }
                }}
            >
                {isSpamming ? 'Stop spam' : 'Start spam'}
            </Button>
            <Button
                onClick={async () => {
                    try {
                        await axios.post('http://localhost:8080/submit-swing', {});
                    } catch (error) {
                        console.error('Error calling beast simulator', error);
                    }
                }}
            >
                Add swing
            </Button>
        </div>
    );
};

export const NavigationMenu = () => {
    const navigate = useNavigate();
    const { user, logout } = useSession();
    const { logout: logoutBooth } = useBoothSession();

    return (
        <Menu label="Actions" align="left" buttonSize={24} menuType="main" className={css.menu}>
            <MenuItem onClick={() => navigate('user-settings')} style={{ justifyContent: 'flex-start' }}>
                <GearIcon size={18} />
                <span
                    className={typography({
                        variant: 'p',
                    })}
                >
                    {localize('menu.user_settings')}
                </span>
            </MenuItem>
            <MenuItem onClick={() => navigate('overview')} style={{ justifyContent: 'flex-start' }}>
                <ClockIcon size={18} />
                <span
                    className={typography({
                        variant: 'p',
                    })}
                >
                    {localize('menu.activity_overview')}
                </span>
            </MenuItem>

            <MenuItem onClick={logout} style={{ justifyContent: 'flex-start' }}>
                <SignOutIcon size={18} />
                <span
                    className={typography({
                        variant: 'p',
                    })}
                >
                    {localize('menu.end_session')}
                </span>
            </MenuItem>

            {user?.isAdmin && (
                <MenuItem
                    onClick={() => {
                        logoutBooth();
                        logout();
                    }}
                    style={{ justifyContent: 'flex-start' }}
                >
                    <XCircleIcon weight="fill" size={18} />
                    <span
                        className={typography({
                            variant: 'p',
                        })}
                    >
                        {localize('menu.end_booth_session')}
                    </span>
                </MenuItem>
            )}

            <MenuItem onClick={() => window.location.reload()} style={{ justifyContent: 'flex-start' }}>
                <XCircleIcon size={18} />
                <span
                    className={typography({
                        variant: 'p',
                    })}
                >
                    {localize('menu.reboot')}
                </span>
            </MenuItem>
        </Menu>
    );
};

export default function Navigation({ isActivityReadOnly = false }: { isActivityReadOnly: boolean }) {
    const navigate = useNavigate();
    const { user } = useSession();

    const [swingID] = useGlobalStore((state) => [state.activeSwingID]);
    const { activeLayout, activityClub, activityTags, userSettings } = useUserSettingsStore(
        ({ activeLayout, activityClub, activityTags, userSettings }) => ({
            activeLayout,
            activityClub,
            activityTags,
            userSettings,
        }),
    );
    const activeLayoutName = activeLayout?.name ?? 'No Layout';

    const currentTags = useMemo(() => {
        if (!Array.isArray(activityTags) || activityTags.length === 0) {
            return [{ label: 'Select' }];
        }

        // Limit tags to
        const MAX_TAGS = 3;

        if (activityTags.length <= MAX_TAGS) {
            return activityTags.map((tag) => ({ label: tag.name }));
        }

        return [
            ...activityTags.slice(0, MAX_TAGS).map((tag) => ({ label: tag.name })),
            { label: `+${activityTags.length - MAX_TAGS}` },
        ];
    }, [activityTags]);

    const currentClub = activityClub ? { label: activityClub.short_name } : { label: 'Select' };
    const hasSwingID = typeof swingID === 'number' && swingID >= 0;

    return (
        <div className={css.root}>
            <ElvaLogoName />

            <div className={css.actionGroupLayout}>
                <PropertyTrigger
                    label="Club"
                    values={[currentClub]}
                    onClick={() => {
                        if (isActivityReadOnly) return;
                        navigate(`clubs?display=${_.isEmpty(userSettings.golfBag) ? 'all' : 'bag'}`);
                    }}
                />
                <PropertyTrigger
                    label="Tags"
                    values={currentTags}
                    onClick={() => {
                        if (isActivityReadOnly) return;
                        navigate('tags');
                    }}
                />
                <PropertyTrigger
                    label="Layout"
                    values={[{ label: activeLayoutName, icon: 'layout' }]}
                    onClick={() => {
                        // TODO: We need to add the layout's ID to the route
                        navigate('layout-editor');
                    }}
                />
                {hasSwingID && <PropertyTrigger label="ID" values={[{ label: `#${swingID}` }]} />}
            </div>
            {import.meta.env.DEV && <DevTools />}
            <nav>
                <ul className={css.buttons}>
                    <li className={css.action}>
                        <Button
                            icon="camera"
                            variant="tertiary"
                            iconColor={colors.blue[500]}
                            iconWeight="fill"
                            onClick={() => {
                                navigate('playback');
                            }}
                        >
                            Playback
                        </Button>
                    </li>

                    <li>
                        <div className={css.menuButton}>
                            <span
                                className={typography({
                                    variant: 'h3',
                                })}
                            >
                                {user?.name ? _.startCase(user.name) : localize('menu.actions')}
                            </span>
                            <NavigationMenu />
                        </div>
                    </li>
                </ul>
            </nav>
        </div>
    );
}
