// TODO: @common
import { Search } from '../icons/Search';

import * as css from './SearchInput.css';

export function SearchInput({
    value,
    onChange,
    placeholder,
}: {
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
}) {
    return (
        <div className={css.inputField}>
            <input
                className={css.input}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                placeholder={placeholder}
            />
            <div className={css.searchIcon}>
                <Search />
            </div>
        </div>
    );
}
