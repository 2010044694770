import _ from 'lodash';

import type { Swing } from '@core';
import { typography } from '../../typography';
import { ActivityNavigationItem, GhostNavigationItem } from './Item';

import * as css from './ActivityNavigation.css';

type EnhancedSwing = Swing & { value: string; number: number };

type ActivityNavigationProps = {
    heading: string;
    onToggleFavorite: (val: boolean, id: number) => void;
    onToggleComparison: (val: boolean, id: number) => void;
    selectedSwingId?: number;
    comparisonSwingId?: number;
    onClickKeyParameter: () => void;
    selectedKeyParameter: string;
    onSelectSwing: (swing: Swing) => void;
    swings: EnhancedSwing[];
    debug?: boolean;
};

export function ActivityNavigation({
    heading,
    onToggleFavorite,
    swings,
    selectedKeyParameter,
    onClickKeyParameter,
    onSelectSwing,
    onToggleComparison,
    selectedSwingId,
    comparisonSwingId,
    debug = false,
}: ActivityNavigationProps) {
    return (
        <aside className={css.aside}>
            <div className={css.header}>
                <h3
                    className={typography({
                        variant: 'h3',
                    })}
                >
                    {heading}
                </h3>
                <button onClick={onClickKeyParameter} className={css.activityNavigationParameter}>
                    <span className={typography({ variant: 'h4' })}>{selectedKeyParameter}</span>
                </button>
            </div>
            <ul className={css.list}>
                {_.isEmpty(swings) ? (
                    <GhostNavigationItem />
                ) : (
                    _.map(swings, (swing) => (
                        <ActivityNavigationItem
                            key={swing.id}
                            id={swing.id}
                            number={swing.number}
                            value={swing.value}
                            status={swing.fullAnalysis ? 'ready' : 'analyzing'}
                            isSelected={selectedSwingId === swing.id}
                            isComparison={comparisonSwingId === swing.id}
                            isFavorite={swing.isFavorite}
                            debug={debug}
                            onSelect={() => {
                                onSelectSwing(swing);
                            }}
                            onToggleComparison={(val) => onToggleComparison(val, swing.id)}
                            onToggleFavorite={(val) => onToggleFavorite(val, swing.id)}
                        />
                    ))
                )}
            </ul>
        </aside>
    );
}
