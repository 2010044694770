import { useMemo } from 'react';
import _ from 'lodash';

import { inferSwingPosition, type UIParameter, type Analysis } from '@common';
import { useSelectedAnalysis } from '../../../../state/globalStore';
import { type ImplementationOf, isPropsForType, type UIComponentProps } from '../../UIComponent.types';
import { useSession } from '../../../../utils/hooks/useSession';
import {
    SwingFoundationsRenderer as KioskRenderer,
    type SwingFoundationsRendererProps,
} from './SwingFoundationsRenderer.kiosk';
import { SwingFoundationsRenderer as FloorRenderer } from './SwingFoundationsRenderer.floor';

export const SwingFoundations: ImplementationOf<'swing_foundations'> = ({
    node: { id, name },
    currentDevice,
    children,
    uiNodeTree: { categories },
}) => {
    const shouldUseLatestSwing = currentDevice === 'floor';
    const analysis: Analysis | null = useSelectedAnalysis(shouldUseLatestSwing) || null;

    const parameterProps = useMemo(
        () => _.filter(children, (child) => isPropsForType(child, 'parameter')) as UIComponentProps<UIParameter>[],
        [children],
    );

    const swingFoundationGroups = useMemo(
        () =>
            _(categories)
                .filter((c) => _.startsWith(c.id, 'swing_foundation_group.'))
                .keyBy((c) => c.id)
                .value() as SwingFoundationsRendererProps['swingFoundationGroups'],
        [categories],
    );

    const { cameraConfig } = useSession();

    const kioskRenderer = useMemo(
        () => {
            if (!cameraConfig) {
                console.error("[UIComponent('swing_foundations')]: Missing camera config");
                return <div key={id}></div>;
            }
            return (
                <KioskRenderer
                    cameraConfig={cameraConfig}
                    videos={analysis?.data.videos}
                    name={name}
                    parameterProps={parameterProps}
                    swingFoundationGroups={swingFoundationGroups}
                />
            );
        },
        [analysis, parameterProps], // eslint-disable-line react-hooks/exhaustive-deps
    );

    const floorRenderer = useMemo(
        () => {
            const position = inferSwingPosition(id);
            return position ? (
                <FloorRenderer
                    parameterProps={parameterProps}
                    segmentation={analysis?.data.analysis?.segmentation}
                    images={analysis?.data?.images}
                    position={position}
                />
            ) : (
                <div />
            );
        },
        [analysis, parameterProps, id], // eslint-disable-line react-hooks/exhaustive-deps
    );

    switch (currentDevice) {
        case 'kiosk':
            return kioskRenderer;
        case 'floor':
            return floorRenderer;
        default:
            return <div />;
    }
};
