export function isSVGElement(element: Element) {
    // Check if element is an SVG element itself
    if (element instanceof SVGElement) {
        return true;
    }

    // Check specific SVG element types
    if (
        element instanceof SVGPathElement ||
        element instanceof SVGCircleElement ||
        element instanceof SVGRectElement ||
        element instanceof SVGTextElement
    ) {
        return true;
    }

    // Check if element is a child of an SVG element
    let parent = element.parentElement;
    while (parent) {
        if (parent instanceof SVGElement) {
            return true;
        }
        parent = parent.parentElement;
    }

    return false;
}
