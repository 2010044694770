import {
    type IconWeight,
    ChartLine as ChartLineIcon,
    Plus as PlusIcon,
    Pencil as PencilIcon,
    PlusCircle as PlusCircleIcon,
    PencilCircle as PencilCircleIcon,
    VideoCamera as CameraIcon,
} from '@phosphor-icons/react';
import { useCallback, type ReactNode } from 'react';
import cn from 'classnames';

import { typography } from '../../../typography';

import { type ButtonVariants } from './Button.css';
import * as css from './Button.css';

interface IButtonProps {
    children: ReactNode;
    type?: 'submit' | 'button';
    variant?: ButtonVariants['variant'];
    size?: ButtonVariants['size'];
    onClick?: () => void;
    icon?: keyof typeof availableIcons;
    isDisabled?: boolean;
    iconWeight?: IconWeight;
    iconSide?: 'before' | 'after';
    iconSize?: number;
    iconColor?: string;
    isLoading?: boolean;
}

const availableIcons = {
    'chart-line': { component: ChartLineIcon, defaultSize: 14 },
    plus: { component: PlusIcon, defaultSize: 14 },
    'plus-circle': { component: PlusCircleIcon, defaultSize: 18 },
    pencil: { component: PencilIcon, defaultSize: 18 },
    'pencil-circle': { component: PencilCircleIcon, defaultSize: 24 },

    camera: { component: CameraIcon, defaultSize: 18 },
};

const fontSizes: Record<NonNullable<ButtonVariants['size']>, string> = {
    icon: typography({ variant: 'h4' }),
    tiny: typography({ variant: 'h4' }),
    small: typography({ variant: 'h4' }),
    medium: typography({ variant: 'h3' }),
    large: typography({ variant: 'h2' }),
};

export function Button({
    children,
    type = 'button',
    variant,
    size = 'medium',
    isDisabled = false,
    icon,
    iconWeight,
    iconSide = 'before',
    iconSize,
    iconColor = 'currentColor',
    onClick = () => {},
    isLoading = false,
}: IButtonProps) {
    const Icon = icon && availableIcons[icon].component;

    // Force `primary` variant into `secondary` when it's disabled.
    const effectiveVariant = variant === 'primary' && isDisabled ? 'secondary' : variant;

    const handleClick = useCallback(() => {
        if (isLoading) {
            return;
        }

        if (typeof onClick === 'function') {
            onClick();
        }
    }, [onClick, isLoading]);

    return (
        <button
            className={css.button({ variant: effectiveVariant, size, isDisabled, isLoading })}
            onClick={handleClick}
            disabled={isDisabled || isLoading}
            type={type}
        >
            <span className={css.content} style={{ opacity: isLoading ? 0 : 1 }}>
                <span className={cn([css.text, fontSizes[size]])}>{children}</span>
                {Icon && (
                    <span className={css.icon({ side: iconSide })}>
                        <Icon
                            weight={iconWeight}
                            color={iconColor}
                            width={iconSize ?? availableIcons[icon].defaultSize}
                            height={iconSize ?? availableIcons[icon].defaultSize}
                        />
                    </span>
                )}
            </span>
            {isLoading && (
                <svg
                    className={css.spinner}
                    width={iconSize ?? 24}
                    height={iconSize ?? 24}
                    viewBox="0 0 66 66"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle
                        className={css.path}
                        fill="none"
                        strokeWidth="6"
                        strokeLinecap="round"
                        cx="33"
                        cy="33"
                        r="30"
                    />
                </svg>
            )}
        </button>
    );
}
