import '../styles/fonts.css';
import '../styles/reset.css';

import { useRef } from 'react';
import _ from 'lodash';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';
import { Spinner } from '@ui';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import ConnectionPendingScreen from '../screens/ConnectionPendingScreen';
import FloorScreen from '../screens/FloorScreen';
import FloorConnectionPendingScreen from '../screens/FloorConnectionPendingScreen';
import ResetPasswordScreen from '../screens/ResetPasswordScreen';
import MessageOverlay from '../components/MessageOverlay/MessageOverlay';
import { EDeviceType } from '../utils/types/environment';
import { useUserSettingsStore } from '../state/userSettingsStore';
import { UserSettingsProvider } from '../utils/contexts/UserSettingsContext';
import ClubSelection from '../screens/ClubSelection/ClubSelection';
import TagSelection from '../screens/TagSelection/TagSelection';
import UserSettings from '../screens/UserSettings/UserSettings';
import { SessionProvider } from '../utils/contexts/SessionContext';
import { useSession } from '../utils/hooks/useSession';
import { useGlobalStore } from '../state/globalStore';
import { ActivityOverview } from '../screens/ActivityOverview';
import { BoothLoginScreen } from '../screens/BoothLoginScreen/BoothLoginScreen';
import { ChangePasswordScreen } from '../screens/ChangePasswordScreen';
import { BoothSessionProvider } from '../utils/contexts/BoothSessionContext';
import { useBoothSession } from '../utils/hooks/useBoothSession';
import LayoutEditor from '../screens/LayoutEditor';
import SwingFoundationsEditor from '../screens/SwingFoundationsEditor';
import PlaybackScreen from '../screens/PlaybackScreen';
import ActivityDetails from '../screens/ActivityDetails';

import * as css from './app.css';
import KioskLayout from '../screens/KioskLayout';
import { ActivityContextProvider } from '../utils/contexts/ActivityContext';

const supabase = createClient(
    import.meta.env['VITE_PUBLIC_SUPABASE_URL'],
    import.meta.env['VITE_PUBLIC_SUPABASE_ANON_KEY'],
    {
        auth: {
            storageKey: 'elva-user.session',
        },
    },
);

const supabaseBoothClient = createClient(
    import.meta.env['VITE_PUBLIC_SUPABASE_URL'],
    import.meta.env['VITE_PUBLIC_SUPABASE_ANON_KEY'],
    {
        auth: {
            storageKey: 'elva-booth.session',
        },
    },
);

const queryClient = new QueryClient();

export default function App() {
    const location = useLocation();

    if (location.pathname === '/change-password') {
        return (
            <QueryClientProvider client={queryClient}>
                <SessionProvider supabaseClient={supabase}>
                    <div className={css.app}>
                        <ChangePasswordScreen
                            subtitle="In order to continue, please update your password."
                            returnToKioskOnSuccess
                        />
                    </div>
                </SessionProvider>
            </QueryClientProvider>
        );
    }

    return (
        <QueryClientProvider client={queryClient}>
            <BoothSessionProvider supabaseBoothClient={supabaseBoothClient}>
                <div className={css.app}>
                    <AppWithUserSession />
                </div>
            </BoothSessionProvider>
        </QueryClientProvider>
    );
}

export function AppWithUserSession() {
    const { boothSessionDetails, isLoading, isResettingPassword } = useBoothSession();
    const location = useLocation();

    const isQrLogin = location.pathname === '/login' && _.includes(location.search, 'uid=');
    const currentDeviceType = import.meta.env['VITE_PUBLIC_DEVICE_TYPE'];
    const returnTo = String(currentDeviceType).toUpperCase() === EDeviceType.KIOSK ? '/kiosk' : '/floor';

    if (isLoading) {
        return <Spinner size="l" isAbsolute />;
    }

    if (!boothSessionDetails && !isQrLogin && !isResettingPassword) {
        return <BoothLoginScreen returnTo={returnTo} />;
    }

    return (
        <SessionProvider supabaseClient={supabase}>
            <BoothApp returnTo={returnTo} />
        </SessionProvider>
    );
}

function BoothApp({ returnTo }: { returnTo: string }) {
    const location = useLocation();
    const { isLoggingOut, isLoading, user } = useSession();

    const state = location.state as { backgroundLocation?: Location };
    const userSettingsStore = useRef(useUserSettingsStore()).current;
    const showSpinner = useGlobalStore((state) => state.loadingSpinnerVisible);

    if (isLoading) {
        return <Spinner size="l" isAbsolute />;
    }

    if (
        (user?.shouldChangePassword && location.pathname !== '/reset-password') ||
        location.pathname === '/change-password'
    ) {
        return <ChangePasswordScreen subtitle="In order to continue, please update your password." />;
    }

    return (
        <>
            {isLoggingOut && <MessageOverlay message="Ending session..." />}
            {showSpinner && <MessageOverlay message="Loading activity..." />}
            <ActivityContextProvider supabaseClient={supabase}>
                <UserSettingsProvider store={userSettingsStore} supabaseClient={supabase}>
                    <Routes location={state?.backgroundLocation || location}>
                        <Route path="/floor" element={user ? <FloorScreen /> : <FloorConnectionPendingScreen />} />
                        <Route path="/kiosk" element={<KioskLayout />}>
                            <Route index element={<Navigate to="/kiosk/current" />} />

                            {/* The Kiosk always has an active activity */}
                            <Route path=":id" element={<ActivityDetails />}>
                                <Route path="playback">
                                    <Route index element={<PlaybackScreen display="playback" />} />
                                    <Route path="comparison" element={<PlaybackScreen display="comparison" />} />
                                </Route>
                                <Route path="layout-editor" element={<LayoutEditor />} />
                                <Route path="user-settings" element={<UserSettings />} />
                                <Route path="clubs" element={<ClubSelection />} />
                                <Route path="tags" element={<TagSelection supabase={supabase} />} />
                                <Route path="swing-foundations-editor" element={<SwingFoundationsEditor />} />
                                <Route path="overview" element={<ActivityOverview />} />
                            </Route>
                        </Route>

                        <Route path="/login" element={user ? <Navigate to="/" /> : <ConnectionPendingScreen />} />

                        <Route path="/reset-password" element={<ResetPasswordScreen />} />

                        <Route path="*" element={<Navigate to={returnTo} />} />
                    </Routes>
                </UserSettingsProvider>
            </ActivityContextProvider>
        </>
    );
}
