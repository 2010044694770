import { SocketPayload, useRelaySocketStore } from '../state/relaySocketStore';
import { useEffect } from 'react';
import { useUserSettingsStore } from '../state/userSettingsStore';

type SyncMessage = [
    SocketPayload['activeLayout'],
    SocketPayload['userSettings'],
    SocketPayload['playbackSettings'],
    SocketPayload['activityClub'],
    SocketPayload['activityTags'],
    SocketPayload['activityId'],
];

export function RelayConnection() {
    const sendSyncMessage = useRelaySocketStore((state) => state.actions.sendSyncMessage);

    // Every piece of Kiosk state that should be synced with the floor.
    useEffect(() => {
        const unsubscribe = useUserSettingsStore.subscribe(
            (state) =>
                [
                    state.activeLayout,
                    state.userSettings,
                    state.playbackSettings,
                    state.activityClub,
                    state.activityTags,
                    state.activityId,
                ] as SyncMessage,
            ([activeLayout, userSettings, playbackSettings, activityClub, activityTags, activityId]) => {
                sendSyncMessage({
                    activeLayout,
                    userSettings,
                    playbackSettings,
                    activityClub,
                    activityTags,
                    activityId,
                });
            },
            {
                fireImmediately: true,
            },
        );

        return () => {
            unsubscribe();
        };
    }, [sendSyncMessage]);

    return null;
}
