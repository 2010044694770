import { memo, useMemo, useCallback } from 'react';
import _ from 'lodash';

import { UIComponentProps } from './UIComponent.types';
import { ALL_DEVICES, UINodeRelation, interpolateMetadata } from '@core';
import './implementations';
import { IS_DEV } from '../../utils/consts';
import { createImplementation } from './implementations';

function UIComponentImpl(props: UIComponentProps): JSX.Element {
    const { currentNode, uiNodeTree, currentDevice, layout, metadataOverride, values$ } = props;

    const metadata = useMemo(
        () => ({
            ...currentNode.metadata,
            ...metadataOverride,
        }),
        [currentNode.metadata, metadataOverride],
    );

    const children = useMemo(() => {
        return _((layout?.customizations as UINodeRelation[]) || [])
            .concat(uiNodeTree.relations || [])
            .filter((r) => r && (r.parent_ui_node_id || null) === (currentNode.id || null))
            .groupBy((r) => r.child_ui_node_id) // only need to group by child_ui_node_id since we've already filtered by parent_ui_node_id
            .pickBy(
                (
                    perChild, // the order is determined by the concat operation above (layout.customizations first, then uiNodeTree.relations)
                ) =>
                    //currentDevice === 'kiosk' && hasChildOfType(perChild[0], 'graph_line') //? hack to ensure graph_lines are always visible on kiosk
                    //||
                    _.includes(
                        // since layout.customizations take precedence over uiNodeTree.relations,
                        // we simply use the first non-nil show_child_on we can find
                        _.find(perChild, (r) => !!r.show_child_on)?.show_child_on || ALL_DEVICES,
                        currentDevice,
                    ),
            )
            .map((perChild, id) => ({
                id,
                metadataOverride: interpolateMetadata(perChild),
                order: _(perChild)
                    .reverse()
                    .map((r) => r.child_display_order)
                    .value(),
            }))
            .sortBy((r) => r.order)
            .map(
                ({ id, metadataOverride }) =>
                    ({
                        ...props,
                        metadataOverride,
                        currentNode: _.find(uiNodeTree.nodes, (c) => c.id === id),
                    }) as UIComponentProps,
            )
            .value();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layout?.customizations, uiNodeTree]);

    const node = useMemo(
        () =>
            _.isEqual(metadata, currentNode.metadata)
                ? currentNode
                : {
                      ...currentNode,
                      metadata,
                  },
        [metadata, currentNode],
    );

    const implementationProps = useCallback(
        () => ({
            node,
            children,
            currentDevice,
            uiNodeTree,
            values$,
        }),
        [node, children, currentDevice, uiNodeTree, values$],
    );

    return createImplementation(implementationProps());
}

export default memo(function UIComponent(props: UIComponentProps): JSX.Element {
    if (!props.currentNode) {
        console.error('UIComponent: currentNode not specified', props);
        return IS_DEV ? <div>UI Component not found</div> : <> </>;
    }

    return <UIComponentImpl {...props} />;
});
