import type { Observable } from 'rxjs';

import type {
    AnyUINode,
    Corridor,
    Device,
    Empty,
    Layout,
    MetadataOf,
    Mutable,
    Swing,
    UINodeTree,
    UINodeType,
    SwingPosition,
} from '@core';

export interface UIComponentProps<TNode extends AnyUINode = AnyUINode> {
    readonly uiNodeTree: UINodeTree;
    readonly currentDevice: Device;
    readonly currentNode: TNode;
    readonly layout?: Layout;
    readonly metadataOverride?: MetadataOf<TNode>;
    readonly values$: Observable<UIComponentValues>;
}

export interface UIComponentImplementationProps<T extends UINodeType = UINodeType> {
    readonly node: Extract<AnyUINode, { id: `${T}.${string}`; type: T }>;
    readonly children: UIComponentProps[];
    readonly currentDevice: Device;
    readonly uiNodeTree: UINodeTree;
    readonly values$: Observable<UIComponentValues>;
}

export interface UIComponentValues {
    readonly allSwings?: Swing[];
    readonly swing?: Swing;
    readonly comparisonSwing?: Swing;
    readonly swingPosition?: SwingPosition;
    readonly corridorPerDevice?: { [device in Device]: Corridor };
    readonly setValues?: (valuesToSet: Partial<Mutable<UIComponentValues>>) => void;
}

export type ImplementationOf<T extends UINodeType, TExtraProps = Empty> = (
    props: UIComponentImplementationProps<T> & TExtraProps,
) => JSX.Element;

export function isPropsForType<TNode extends AnyUINode>(
    props: UIComponentProps,
    type: TNode['type'],
): props is UIComponentProps & { currentNode: TNode; metadataOverride: TNode['metadata'] } {
    return props.currentNode?.type === type;
}
