import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Container from '../../components/Container';
import VStack from '../../components/VStack';
import HStack from '../../components/HStack';
import ElvaLogoName from '../../components/Logo/ElvaLogoName';
import Input from '../../components/Input';
import Card from '../../components/Card';
import { useBoothSession } from '../../utils/hooks/useBoothSession';
import { colors, Divider, Button } from '@common/ui';

import * as css from '../ConnectionPendingScreen/ConnectionPendingScreen.css';

type FormValues = {
    email: string;
    password: string;
};

type BoothLoginScreenProps = {
    returnTo: string;
};

export const BoothLoginScreen = ({ returnTo }: BoothLoginScreenProps) => {
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const { login } = useBoothSession();
    const navigate = useNavigate();
    const { register, handleSubmit } = useForm<FormValues>();

    const handleLogin = async ({ email, password }: FormValues) => {
        setIsLoading(true);
        try {
            await login(email, password);
            navigate(returnTo);
        } catch (error) {
            setError(error as string);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container>
            <Card>
                <VStack spacing={20}>
                    <HStack center style={{ justifyContent: 'space-between' }}>
                        <VStack spacing={5}>
                            <h4>Device login</h4>
                            <p style={{ fontSize: '0.8em', color: colors.gray[400] }}>
                                Authenticate your device to continue
                            </p>
                        </VStack>
                        <ElvaLogoName width={90} />
                    </HStack>
                    <Divider />
                    <form onSubmit={handleSubmit(handleLogin)}>
                        <HStack center spacing={60}>
                            <VStack spacing={20} className={css.inputsContainer}>
                                {error && <span className={css.errorMessage}>{error}</span>}
                                <Input
                                    {...register('email')}
                                    required
                                    type="email"
                                    label="Device email address"
                                    placeholder="booth1@elva.golf"
                                />
                                <Input
                                    {...register('password')}
                                    required
                                    type="password"
                                    label="Password"
                                    placeholder="Enter your password"
                                />
                                <Button type="submit" isLoading={isLoading} variant="primary">
                                    Sign in
                                </Button>
                            </VStack>
                        </HStack>
                    </form>
                </VStack>
            </Card>
        </Container>
    );
};
