import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { Button, colors, Spinner } from '@ui';
import { Divider } from '@ui';
import Container from '../../components/Container';
import VStack from '../../components/VStack';
import HStack from '../../components/HStack';
import Input from '../../components/Input';
import Card from '../../components/Card';
import QRCodeWithBottomLabel from './components/QRCodeWithBottomLabel';
import ElvaLogoName from '../../components/Logo/ElvaLogoName';
import { useSession } from '../../utils/hooks/useSession';
import { UserSettingsContext } from '../../utils/contexts';
import { useBoothSession } from '../../utils/hooks/useBoothSession';

import * as css from './ConnectionPendingScreen.css';

interface FormValues {
    email: string;
    password: string;
}

function ConnectionPendingScreen() {
    const [error, setError] = useState(''); // Errors for the user
    const [isLoggedInWithQr, setIsLoggedInWithQr] = useState(false); // If the user is logged in with a QR code
    const userSettingsContext = useContext(UserSettingsContext);

    const [params] = useSearchParams();
    const uidFromQuery = params.get('uid') ?? undefined;
    const { register, handleSubmit } = useForm<FormValues>();
    const { login, isLoading, isLoggingIn } = useSession();
    const { boothSessionDetails } = useBoothSession();

    const handleLogin = async ({ email, password }: FormValues) => {
        try {
            await login(email, password, uidFromQuery);

            if (uidFromQuery) {
                return setIsLoggedInWithQr(true);
            }

            userSettingsContext?.getSettingsStore();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            setError(error.message);
        }
    };

    const qrValue = `${import.meta.env['VITE_PUBLIC_URL']}/login${
        boothSessionDetails ? `?uid=${boothSessionDetails.uid}` : ''
    }`;

    return (
        <Container>
            {isLoading ? (
                <Spinner size="l" isAbsolute />
            ) : (
                <Card>
                    {!isLoggedInWithQr ? (
                        <VStack spacing={20}>
                            <HStack center style={{ justifyContent: 'space-between' }}>
                                <VStack spacing={5}>
                                    <h4>Login</h4>
                                    <p style={{ fontSize: '0.8em', color: colors.gray[400] }}>
                                        Log in with your email or scan the QR code
                                    </p>
                                </VStack>
                                <ElvaLogoName width={90} />
                            </HStack>
                            <Divider />
                            <form onSubmit={handleSubmit(handleLogin)}>
                                <HStack center spacing={60}>
                                    {!uidFromQuery && <QRCodeWithBottomLabel qrValue={qrValue} />}
                                    <VStack spacing={20} className={css.inputsContainer}>
                                        {error && <span className={css.errorMessage}>{error}</span>}
                                        <Input
                                            {...register('email')}
                                            required
                                            type="email"
                                            label="Email Address"
                                            placeholder="you@example.com"
                                        />
                                        <Input
                                            {...register('password')}
                                            required
                                            type="password"
                                            label="Password"
                                            placeholder="Enter your password"
                                        />
                                        <a className={css.forgotLink} href={'/reset-password'}>
                                            Forgot password?
                                        </a>
                                        <Button
                                            type="submit"
                                            isDisabled={isLoggingIn}
                                            variant="primary"
                                            isLoading={isLoggingIn}
                                        >
                                            Sign in
                                        </Button>
                                    </VStack>
                                </HStack>
                            </form>
                        </VStack>
                    ) : (
                        <div>You have successfully joined a new session, and can close this browser window.</div>
                    )}
                </Card>
            )}
        </Container>
    );
}

export default ConnectionPendingScreen;
